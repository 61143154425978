<template>
  <div>
    <div id="main_container">
      <div id="search_top_bar2">
        <input style="border-radius: 15px; padding: 5px;" type="text" placeholder="Wprowadź nazwę lub kod np A-01" v-model="search_text">
        <div class="checkBox" v-if="false">
          <input type="checkbox" class="checkBox_box">
          <div>
            <div>Pokaż wszystkie</div> <div class="small_text">(zaznaczenie pokaże cały katalog bez zastosowania wyszukiwarki - może powodować problemy na słabszych urządzeniach)</div>
          </div>
        </div>
      </div>
      <div v-for="(element, index) in filtered_CodeList" :key="index">
        <div :id="[new_law_serach ? 'containerMy' : 'container_warn']">
          <div class="master_title">
            Kod czynu: {{element.full_name}}
          </div>
          <div class="master_title2">
            Nazwa: {{element.description}}
          </div>
          <div class="master_title2">
            Punkty: {{element.points}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CodeListData from '@/views/Data/code_array_data.json'
import CodeListDataNew from '@/views/Data/code_array_data_new.json'
import { mapState } from 'vuex'
export default {
  name: 'CodeList.vue',
  data () {
    return {
      CodeList: [],
      search_text: ''
    }
  },
  computed: {
    ...mapState([
      'new_law',
      'new_law_serach'
    ]),
    filtered_CodeList () {
      return this.CodeList.filter(element => element.full_name.toLowerCase().includes(this.search_text.toLowerCase()) || element.description.toLowerCase().includes(this.search_text.toLowerCase()))
    }
  },
  created () {
    if (this.new_law) {
      if (this.new_law_serach) {
        this.CodeList = CodeListDataNew.code_array
      } else this.CodeList = CodeListData.code_array
    } else this.CodeList = CodeListData.code_array
  }
}
</script>

<style scoped lang="scss">
@import "src/views/main_layout";
@import "src/views/others";

#containerMy {
  padding: 10px;
  background-color: #3D5A80;
  margin: 10px;
  border-radius: $radius;
}
#container_warn {
  padding: 10px;
  background-color: #3D5A80;
  margin: 10px;
  border-style: solid;
  border-color: #ea0c68;
  border-radius: $radius;
}
.master_title2 {
  text-align: center;
  color: #98C1D9;
  font-family: 'Readex Pro', sans-serif;
  font-weight: bold;
  font-size: 18px;
}
</style>
