<template>
  <div class="small_main_container" id="waiting">
    <div>
      <div>
        Proszę czekać
      </div>
      <i class="fas fa-cog fa-spin" style="font-size: 30px"></i>
      <div class="smallText">
        Serwer pracuje nad nowym projektem radaru
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Waiting',
  methods: {
    showWaiting () {
      document.getElementById('waiting').style.display = 'flex'
      document.getElementById('waiting').style.visibility = 'unset'
    },
    hideWaiting () {
      document.getElementById('waiting').style.display = 'none'
      document.getElementById('waiting').style.visibility = 'hidden'
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/main_layout";
@import "src/views/others";

#waiting {
  font-family: $text-family;
  // display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  display: none;
  visibility: hidden;
}
.smallText {
  font-size: 12px;
}

</style>
