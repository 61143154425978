<template>
  <div>
    <div id="view">
      <div v-for="(element, index) in sort_data" :key="index" >
        <tariffCard
          :data="element"
        />
      </div>
    </div>
  </div>
</template>

<script>
import tariffDataNew from '@/views/Data/tariff_data_new.json'
import tariffCard from '@/components/tariffCard'

export default {
  name: 'Recidivism.vue',
  components: {
    tariffCard
  },
  data () {
    return {
      sort_data: [],
      tariffData: tariffDataNew.tariff_array,
      array: [
        'doc-6224aa68440776b3e9eedb02', // A02
        'doc-6224aa364407765c5ceeda02', // B01
        'doc-6224aa364407765c5ceed9ff', // B02
        'doc-6224aa364407765c5ceed9ef', // B03
        'doc-6224aa364407765c5ceed9fd', // B04
        'doc-6224aa364407765c5ceed9f1', // B05
        'doc-6224aa364407765c5ceed9f9', // B06
        'doc-6224aa364407765c5ceeda04', // B07
        'doc-6224aa364407765c5ceed9f4', // B08
        'doc-6224aa364407765c5ceed9f6', // B06
        'doc-6224aa364407765c5ceed9fb', // B10
        'doc-6224aa6844077650eaeeda87', // C01
        'doc-6224aa364407765c5ceeda41', // E01
        'doc-6224aa364407765c5ceeda40', // E02
        'doc-6224aa364407765c5ceeda3f', // E03
        'doc-6224aa364407765c5ceeda3e', // E04
        'doc-6224aa364407765c5ceeda3d', // E05
        'doc-6328d904757158059f6d1d85', // F01
        'doc-6224aa364407765c5ceeda52', // F02
        'doc-6224aa364407765c5ceeda4e', // F03
        'doc-6224aa364407765c5ceeda4f', // F04
        'doc-6224aa364407765c5ceeda50', // F05
        'doc-6224aa364407765c5ceeda53', // F06
        'doc-6224aa364407765c5ceeda54', // F07
        'doc-6328d792757158e27f6d1d84', // F08
        'doc-6224aa364407765c5ceeda51', // F09
        'doc-6224aa3644077642dfeed971', // H10
        'doc-6224aa3644077642dfeed973', // H11
        'doc-6224aa3644077642dfeed975' // H12
      ]
    }
  },
  computed: {
    filtered_data () {
      // var data = []
      // this.array.forEach(element => {
      //   this.tariffData.filter(element2 => element2.id === element)
      //   data.push(this.tariffData.filter(element2 => element2.id === element))
      // }
      // )
      return this.tariffData.filter(element => this.array.includes(element.id))
      // return data
    }
  },
  mounted () {
    this.array.forEach(element => {
      this.tariffData.filter(element2 => element2.id === element)
      this.sort_data.push(this.tariffData.filter(element2 => element2.id === element)[0])
    }
    )
  }
}
</script>

<style scoped lang="scss">
#view {
  height: 100%;
  position: unset;
  max-width: 1080px;
  margin: 0 auto !important;
  float: none !important;
}

</style>
