<template>
  <div style="color: white"><p style="text-align: center;">POLITYKA PRYWATNOŚCI &nbsp;</p>
    <p style="text-align: center;">&sect;1 Postanowienia og&oacute;lne</p>
    <p>&nbsp;1. Ten dokument stanowi załącznik do Regulaminu. Korzystając z naszych usług, powierzasz nam swoje informacje. Niniejsza Polityka prywatności służy jedynie jako pomoc w zrozumieniu, jakie informacje i dane są zbierane i w jakim celu oraz do czego je wykorzystujemy. Te dane są bardzo dla nas ważne, dlatego prosimy o dokładne zapoznanie się z tym dokumentem gdyż określa on zasady oraz sposoby przetwarzania i ochrony danych osobowych. Dokument ten określa także zasady stosowania plik&oacute;w &bdquo;Cookies&rdquo;.&nbsp;</p>
    <p>2. Niniejszym oświadczamy, że przestrzegamy zasad ochrony danych osobowych oraz wszelkich uregulowań prawnych, kt&oacute;re są przewidziane Ustawą o ochronie danych osobowych oraz Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.&nbsp;</p>
    <p>3. Osoba kt&oacute;rej dane osobowe są przetwarzane ma prawo zwr&oacute;cić się do nas celem uzyskania wyczerpujących informacji w jaki spos&oacute;b wykorzystujemy jego dane osobowe. Zawsze w jasny spos&oacute;b staramy się poinformować o danych, kt&oacute;re gromadzimy, w jaki spos&oacute;b je wykorzystujemy, jakim celom mają służyć i komu je przekazujemy, jaką zapewniamy ochronę tych danych przy przekazaniu innym podmiotom oraz udzielamy informacji o instytucjach z kt&oacute;rymi należy się skontaktować w razie wątpliwości. &nbsp;</p>
    <p>4. Serwis stosuje środki techniczne takie jak: środki ochrony fizycznej danych osobowych, środki &nbsp;sprzętowe infrastruktury informatycznej i telekomunikacyjnej, środki ochrony w ramach narzędzi programowych i baz danych oraz środki organizacyjne zapewniające należytą ochronę przetwarzanych danych osobowych, a w szczeg&oacute;lności zabezpieczają dane osobowe przed udostępnieniem ich nieupoważnionym osobom trzecim, uzyskaniem przez osobę nieupoważnioną i wykorzystaniem ich w niewiadomym celu, a także przypadkową lub celową zmianą, utratą, uszkodzeniem lub zniszczeniem takich danych.&nbsp;</p>
    <p>5. Na zasadach określonych w Regulaminie oraz w niniejszym dokumencie posiadamy wyłączny dostęp do danych. Dostęp do danych osobowych może być r&oacute;wnież powierzony innym podmiotom za pomocą, kt&oacute;rych dokonuje się płatności, kt&oacute;re gromadzą, przetwarzają i przechowują dane osobowe zgodnie ze swoimi Regulaminami oraz podmioty, kt&oacute;re mają za zadanie realizację zam&oacute;wienia. Dostęp do danych osobowych jest udzielany w/w podmiotom w zakresie niezbędnym i tylko takim, kt&oacute;ry zapewni realizację usług. 6. Dane osobowe są przetwarzane tylko w takich celach na jakie wyrazili Państwo zgodę poprzez kliknięcia w odpowiednie pola formularza zamieszczonego w Serwisie lub w inny wyraźny spos&oacute;b. Podstawą prawną przetwarzania Państwa danych osobowych jest zgoda na przetwarzanie danych lub wym&oacute;g realizacji usługi (np. zam&oacute;wienie Produktu) kt&oacute;rą u nas zam&oacute;wiłeś (stosownie do artykułu 6 ust. 1 lit a i b Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (og&oacute;lne rozporządzenie o ochronie danych) - RODO.&nbsp;</p>
    <p style="text-align: center;">&sect;2 Zasady prywatności&nbsp;</p>
    <p>1. Poważnie traktujemy prywatność. Charakteryzuje nas szacunek dla prywatności oraz możliwie najpełniejsza i zagwarantowana wygoda z korzystania z naszego usług.&nbsp;</p>
    <p>2. Cenimy zaufanie, jakim obdarzają nas Użytkownicy, powierzając nam swoje dane osobowe w celu realizacji zam&oacute;wienia. Zawsze korzystamy z danych osobowych w spos&oacute;b uczciwy oraz tak, aby nie zawieść tego zaufania, tylko w zakresie niezbędnym do realizacji zam&oacute;wienia w tym jego przetwarzania.&nbsp;</p>
    <p>3. Użytkownik ma prawo do uzyskania jasnych i pełnych informacji o tym, w jaki spos&oacute;b wykorzystujemy jego dane osobowe i do jakich cel&oacute;w są potrzebne. Zawsze w jasny spos&oacute;b informujemy o danych, kt&oacute;re gromadzimy, w jaki spos&oacute;b i komu je przekazujemy oraz udzielamy informacji o podmiotach, z kt&oacute;rymi należy się skontaktować w razie wątpliwości, pytań, uwag.&nbsp;</p>
    <p>4. W razie wątpliwości odnośnie wykorzystywania przez nas danych osobowych Użytkownika, niezwłocznie podejmiemy działania w celu wyjaśnienia i rozwiania takich wątpliwości, w spos&oacute;b pełny i wyczerpujący odpowiadamy na wszystkie pytania z tym związane.</p>
    <p>&nbsp;5. Podejmiemy wszystkie uzasadnione działania, aby chronić dane Użytkownik&oacute;w przed nienależytym i niekontrolowanym wykorzystaniem oraz zabezpieczyć je w spos&oacute;b kompleksowy.&nbsp;</p>
    <p>6. Dane Administrator Państwa danych osobowych znajdują się na w zakładce &bdquo;kontakt&rdquo; umiejscowionej na stronie internetowej. &nbsp;</p>
    <p>7. Podstawą prawną przetwarzania Państwa danych osobowych jest art. 6 ust. 1 lit. b) RODO. Podanie danych nie jest obowiązkowe, ale niezbędne do podjęcia odpowiednich czynności poprzedzających zawarcie umowy oraz jej realizację. Będziemy przekazywać Państwa dane osobowe innym odbiorcom, kt&oacute;rym powierzono przetwarzanie danych osobowych w imieniu i na naszą rzecz. Państwa dane będą przekazywane na podstawie art. 6 ust. 1 lit. f) RODO, gdzie prawnie uzasadnionym interesem jest należyte wykonanie um&oacute;w/zleceń. Ponadto będziemy udostępniać Państwa dane osobowe innym partnerom handlowym. Zebrane dane osobowe przechowujemy na terenie Europejskiego Obszaru Gospodarczego (&bdquo;EOG&rdquo;), ale mogą one być także przesyłane do kraju spoza tego obszaru i tam przetwarzane. Każda operacja przesyłania danych osobowych jest wykonywana zgodnie z obowiązującym prawem. Jeśli dane są przekazywane poza obszar EOG, stosujemy standardowe klauzule umowne oraz tarczę prywatności jako środki zabezpieczające w odniesieniu do kraj&oacute;w, w przypadku kt&oacute;rych Komisja Europejska nie stwierdziła odpowiedniego poziomu ochrony danych.&nbsp;</p>
    <p>8. Państwa dane osobowe związane z zawarciem i realizacją umowy o realizacje um&oacute;w przetwarzane będą przez okres ich realizacji, a także przez okres nie dłuższy niż przewidują to przepisy prawa, w tym przepisy Kodeksu cywilnego oraz ustawy o rachunkowości, tj. nie dłużej niż przez 10 lat, licząc od końca roku kalendarzowego w kt&oacute;rym ostatnia umowa została wykonana.&nbsp;</p>
    <p>9. Państwa dane osobowe przetwarzane w celu zawarcia i wykonania przyszłych um&oacute;w będą przetwarzane do czasu zgłoszenia sprzeciwu.&nbsp;</p>
    <p>10. Przysługuje Państwu prawo do: dostępu do swoich danych osobowych i otrzymania kopii danych osobowych podlegających przetwarzaniu, sprostowania swoich nieprawidłowych danych; żądania usunięcia danych (prawo do bycia zapomnianym) w przypadku wystąpienia okoliczności przewidzianych w art. 17 RODO; żądania ograniczenia przetwarzania danych w przypadkach wskazanych w art. 18 RODO, wniesienia sprzeciwu wobec przetwarzania danych w przypadkach wskazanych w art. 21 RODO, przenoszenia dostarczonych danych, przetwarzanych w spos&oacute;b zautomatyzowany.&nbsp;</p>
    <p>11. Jeżeli uważacie Państwo, że dane osobowe są przetwarzane niezgodnie z prawem, możecie wnieść skargę do organu nadzorczego (Urząd Ochrony Danych Osobowych, ul. Stawki 2, Warszawa). Jeśli potrzebujecie Państwo dodatkowych informacji związanych z ochroną danych osobowych lub chcecie skorzystać z przysługujących praw, skontaktujcie się z nami listownie na adres korespondencyjny.&nbsp;</p>
    <p>12. Dokładamy wszelkich starań, aby chronić przed nieuprawnionym dostępem, nieautoryzowaną modyfikacją, ujawnieniem oraz zniszczeniem informacji znajdujących się w naszym posiadaniu. W szczeg&oacute;lności: a) Kontrolujemy metody gromadzenia, przechowywania i przetwarzania informacji, w tym fizyczne środki bezpieczeństwa, aby chronić przed nieuprawnionym dostępem do systemu. b) Dostępu do danych osobowych udzielamy jedynie tym pracownikom, kontrahentom oraz &nbsp;przedstawicielom, kt&oacute;rzy muszą mieć do nich dostęp. Ponadto na mocy umowy są oni zobowiązani do zachowania ścisłej poufności, do umożliwienia nam kontroli i sprawdzenia jak wywiązują się z powierzonych obowiązk&oacute;w, a w przypadku niewypełnienia tych zobowiązań mogą ponieść konsekwencje.&nbsp;</p>
    <p>13. Będziemy przestrzegać wszystkich obowiązujących przepis&oacute;w i regulacji dotyczących ochrony danych i będziemy wsp&oacute;łpracować z organami zajmującymi się ochroną danych oraz uprawnionymi do tego organami ścigania. W przypadku braku przepis&oacute;w dotyczących ochrony danych, będziemy postępować zgodnie z og&oacute;lnie przyjętymi zasadami ochrony danych, zasadami wsp&oacute;łżycia społecznego jak i ustalonymi zwyczajami.&nbsp;</p>
    <p>14. Dokładny spos&oacute;b ochrony danych osobowych został zawarty w polityce ochrony danych osobowych (ODO: polityka bezpieczeństwa, regulamin ochrony danych osobowych, instrukcja zarządzania systemem informatycznym) Z przyczyn bezpieczeństwa, ze względu na opisane w niej procedury, jest ona do wglądu jedynie dla organ&oacute;w kontroli państwowej. &nbsp;</p>
    <p>15. W razie pytań, odnośnie sposobu, w jaki postępowania z danymi osobowymi, zapraszamy do kontaktu za pomocą strony, z kt&oacute;rej użytkownik został przekierowany do niniejszej Polityki prywatności. Prośba o kontakt zostanie niezwłocznie przekazana do odpowiedniej powołanej do tego osoby.&nbsp;</p>
    <p>16. Użytkownik ma zawsze prawo powiadomić nas, jeśli:&nbsp;</p>
    <p>a) nie chce w jakiejkolwiek formie już otrzymywać od nas informacji lub wiadomości;&nbsp;</p>
    <p>b) pragnie otrzymać posiadaną przez nas kopię swoich danych osobowych;&nbsp;</p>
    <p>c) poprawić, zaktualizować lub usunąć swoje dane osobowe znajdujące się w naszej ewidencji;&nbsp;</p>
    <p>d) pragnie zgłosić naruszenia, nienależyte wykorzystanie bądź przetwarzanie swoich danych osobowych. 17. Aby ułatwić nam odpowiedź bądź ustosunkowanie się do podanych informacji, prosimy o podanie imienia i nazwiska oraz dalej idących szczeg&oacute;ł&oacute;w.&nbsp;</p>
    <p>&sect;3 Zakres i cel zbierania danych osobowych&nbsp;</p>
    <p>1. Przetwarzamy niezbędne dane osobowe w celu realizacji usług oraz w celach księgowych i tylko takich tj. :</p>
    <p>&nbsp;a) w celu złożenia zam&oacute;wienia,&nbsp;</p>
    <p>b) w celu zawarcia umowy, reklamacji oraz odstąpienia od umowy,&nbsp;</p>
    <p>c) wystawienia faktury VAT lub innego paragonu. &nbsp;</p>
    <p>d) monitorowania ruchu na naszych stronach internetowych;&nbsp;</p>
    <p>e) zbieranie anonimowych statystyk, dla ustalenia, w jaki spos&oacute;b użytkownicy korzystają z naszej strony internetowej;&nbsp;</p>
    <p>f) ustalanie liczby anonimowych użytkownik&oacute;w naszych stron&nbsp;</p>
    <p>g) kontrolowanie jak często pokazywana jest użytkownikom wybrana treść i jaka treść najczęściej;&nbsp;</p>
    <p>h) kontrolowanie jak często użytkownicy wybierają daną usługę bądź z poziomu jakiej usługi następuje najczęściej kontakt;&nbsp;</p>
    <p>i) badanie zapis&oacute;w na newslettery i opcje kontaktu;&nbsp;</p>
    <p>j) wykorzystanie systemu personalizowanych rekomendacji dla e-commerce; k) wykorzystanie narzędzia do komunikacji zar&oacute;wno mailowej jak i w następstwie czego telefonicznej; l) integracja z portalem społecznościom; m) ewentualne płatności internetowe. 2. Zbieramy, przetwarzamy i przechowujemy następujące dane użytkownik&oacute;w:&nbsp;</p>
    <p>a) adres poczty elektronicznej (e-mail),&nbsp;</p>
    <p>b) informacje o używanej przeglądarce internetowej, &nbsp;</p>
    <p>c) inne dobrowolnie przekazane nam dane osobowe.&nbsp;</p>
    <p>3. Podanie powyższych danych przez jest całkowicie dobrowolne ale także i niezbędne do pełnej realizacji usług.&nbsp;</p>
    <p>4. Cel gromadzenia i przetwarzania lub wykorzystania przez nas danych: a) marketing bezpośredni, cele archiwalne kampanii reklamowych;&nbsp;</p>
    <p>b) realizacja obowiązk&oacute;w nałożonych przepisami prawa poprzez zbieranie informacji o niepożądanych działaniach;&nbsp;</p>
    <p>5. Możemy przesyłać dane osobowe do serwer&oacute;w znajdujących się poza krajem zamieszkania użytkownika lub do podmiot&oacute;w powiązanych, stron trzecich z siedzibą w innych krajach w tym krajach z obszaru EOG (Europejski Obszar Gospodarczy, EOG ang. European Economic Area, EEA &ndash; strefa wolnego handlu i Wsp&oacute;lny Rynek, obejmujące państwa Unii Europejskiej i Europejskiego Stowarzyszenia Wolnego Handlu EFTA) w celu przetwarzania danych osobowych przez takie podmioty w naszym imieniu zgodnie z postanowieniami niniejszej Polityki prywatności oraz obowiązującymi przepisami prawa, zwyczajami jak i regulacjami dotyczącymi ochrony danych.&nbsp;</p>
    <p>&nbsp;6. Twoje dane osobowe przechowujemy nie dłużej niż są one potrzebne dla właściwej jakości obsługi i w zależności od trybu i celu ich pozyskania przechowujemy je na czas jej trwania oraz po jej zakończeniu w celach:</p>
    <p>&nbsp;a) realizacji obowiązk&oacute;w wynikających z przepis&oacute;w prawa, przepis&oacute;w podatkowych i rachunkowych;&nbsp;</p>
    <p>b) zapobiegania nadużyciom lub przestępstwom;&nbsp;</p>
    <p>c) statystycznych i archiwizacyjnych.&nbsp;</p>
    <p>d) Działania marketingowe &ndash; na czas trwania umowy, udzielenia odrębnej zgody na przetwarzanie takich danych &ndash; do czasu zakończenia działań związanych z obsługą transakcji, wniesienia przez Ciebie sprzeciwu wobec takiego przetwarzania lub wycofania zgody.&nbsp;</p>
    <p>e) Działania okołosprzedażowe i promocyjne &ndash; np. konkursy, akcje promocyjne &ndash; na czas trwania i rozliczenia takich akcji.&nbsp;</p>
    <p>f) Działalność operacyjna - do czasu przedawnienia obowiązk&oacute;w nałożonych przez Rozporządzenie RODO oraz odpowiednie przepisy krajowe, celem wykazania rzetelności w przetwarzaniu danych osobowych&nbsp;</p>
    <p>g) dochodzenia Wszelkich roszczeń związanych ze zrealizowaną umową;&nbsp;</p>
    <p>7. Mając na uwadze okoliczności, że w wielu krajach, do kt&oacute;rych są przesyłane niniejsze dane osobowe nie obowiązuje taki sam poziom ochrony prawnej danych osobowych, jaki obowiązuje w kraju użytkownika. Do danych osobowych użytkownika przechowywanych w innym kraju dostęp zgodnie z prawem tam obowiązującym, dostęp mogą uzyskać na przykład: sądy, organy odpowiedzialne za egzekwowanie prawa i bezpieczeństwo narodowe, zgodnie z przepisami obowiązującymi w tym kraju. Z zastrzeżeniem zgodnych z prawem pr&oacute;śb o ujawnienie danych, zobowiązujemy się wymagać od podmiot&oacute;w przetwarzających dane osobowe poza krajem użytkownika podjęcia działań w celu ochrony danych w adekwatny spos&oacute;b do regulacji ich prawa krajowego.&nbsp;</p>
    <p>&sect;4 Polityka &bdquo;Cookies&rdquo;&nbsp;</p>
    <p>1. Zbieramy w spos&oacute;b automatyczny informacje zawarte w plikach cookies w celu gromadzenia danych Użytkownika. Plik Cookies to mały fragment tekstu, kt&oacute;ry jest wysyłany do przeglądarki Użytkownika i kt&oacute;ry przeglądarka wysyła z powrotem przy następnych wejściach na witrynę. Używane są gł&oacute;wnie do utrzymywania sesji np. poprzez wygenerowanie i odesłanie tymczasowego identyfikatora po logowaniu. Wykorzystujemy pliki Cookies &bdquo;sesyjne&rdquo; przechowywane na urządzeniu końcowym Użytkownika do czasu jego wylogowania, wyłączenia strony internetowej lub wyłączenia przeglądarki internetowej oraz pliki Cookies &bdquo;stałe&rdquo; przechowywane na urządzeniu końcowym Użytkownika przez czas określony w parametrach plik&oacute;w Cookies lub do czasu ich usunięcia przez Użytkownika.&nbsp;</p>
    <p>2. Pliki Cookies dostosowują i optymalizują stronę i jej ofertę dla potrzeb Użytkownik&oacute;w poprzez takie działania jak tworzenie statystyk odsłon oraz zapewnienie bezpieczeństwa. Pliki Cookies niezbędne są r&oacute;wnież do utrzymania sesji po opuszczeniu strony internetowej.&nbsp;</p>
    <p>3. Administrator przetwarza dane zawarte w plikach Cookies za każdym razem gdy strona jest odwiedzana przez odwiedzających w następujących celach:&nbsp;</p>
    <p>a) optymalizacji korzystania ze strony;&nbsp;</p>
    <p>b) identyfikacji Usługobiorc&oacute;w jako w danej chwili zalogowanych;&nbsp;</p>
    <p>c) przystosowania, grafiki, opcji wyboru oraz wszelkiej innej zawartości strony do indywidualnych preferencji Usługobiorcy;&nbsp;</p>
    <p>d) zapamiętywania uzupełnianych w spos&oacute;b automatyczny i manualny, zamieszczanych danych z Formularzy Zam&oacute;wienia lub podanych przez odwiedzającego danych logowania;&nbsp;</p>
    <p>e) gromadzenia i analizowania anonimowych statystyk przedstawiających spos&oacute;b korzystania ze strony w panelu administracyjnym oraz google analytics&nbsp;</p>
    <p>f) tworzenia list remarketingowych na podstawie informacji o preferencjach, zachowaniu, sposobie korzystania zainteresowaniach ze Strony oraz zbierania danych demograficznych, a następnie udostępnianie tych list w AdWords oraz Facebook Ads.&nbsp;</p>
    <p>g) tworzenia segment&oacute;w danych na podstawie informacji demograficznych, zainteresowań, upodobań w wyborze oglądanych produkt&oacute;w/usług.&nbsp;</p>
    <p>h) wykorzystywania danych demograficznych i danych o zainteresowaniach w raportach Analytics.&nbsp;</p>
    <p>4. Użytkownik w każdej chwili za pomocą swojej przeglądarki internetowej może całkowicie zablokować i skasować gromadzenie plik&oacute;w Cookies.&nbsp;</p>
    <p>5. Zablokowanie przez Użytkownika możliwości gromadzenia plik&oacute;w Cookies na jego urządzeniu może utrudnić lub uniemożliwić korzystanie z niekt&oacute;rych funkcjonalności strony do czego Użytkownik jest w pełni uprawniony ale musi w takiej sytuacji mieć świadomość z ograniczeń funkcjonalności.&nbsp;</p>
    <p>6. Użytkownik, kt&oacute;ry nie chce wykorzystywania plik&oacute;w &bdquo;cookies&rdquo; w opisanym powyżej celu w każdej chwili może usunąć je ręcznie. Do zapoznania się ze szczeg&oacute;łową instrukcją postępowania należy odwiedzić stronę internetową producenta używanej przeglądarki internetowej z &nbsp;kt&oacute;rej aktualnie korzysta Użytkownik.&nbsp;</p>
    <p>7. Więcej informacji na temat Cookies dostępnych jest w menu pomocy każdej przeglądarki internetowej. Przykładowe przeglądarki internetowe obsługujące wspomniane pliki &bdquo;Cookies&rdquo;:&nbsp;</p>
    <p>a) Ustawienia plik&oacute;w cookies Internet Explorer&nbsp;</p>
    <p>b) Ustawienia plik&oacute;w cookies Chrome&nbsp;</p>
    <p>c) Ustawienia plik&oacute;w cookies Firefox&nbsp;</p>
    <p>d) Ustawienia plik&oacute;w cookies Opera&nbsp;</p>
    <p>e) Ustawienia plik&oacute;w cookies Safari&nbsp;</p>
    <p>f) Pliki cookies w Android&nbsp;</p>
    <p>g) Pliki cookies w Blackberry&nbsp;</p>
    <p>h) Pliki cookies w iOS (Safari)&nbsp;</p>
    <p>i) Pliki cookies w Windows Phone</p>
    <p style="text-align: center;">&nbsp;&sect;5 Prawa i obowiązki&nbsp;</p>
    <p>1. Mamy prawo a w przypadkach prawem określonych także i ustawowy obowiązek do przekazania wybranych bądź wszystkich informacji dotyczących danych osobowych organom władzy publicznej bądź osobom trzecim, kt&oacute;re zgłoszą takie żądanie udzielenia informacji na podstawie obowiązujących przepis&oacute;w prawa polskiego.&nbsp;</p>
    <p>2. Użytkownik ma prawo dostępu do treści swoich danych osobowych, kt&oacute;re udostępnia, Użytkownik może te dane poprawiać, uzupełniać w każdym czasie, a także ma prawo do żądania aby je usunięto ze swoich baz danych bądź zaprzestano je przetwarzać, bez podawania jakiekolwiek przyczyny. W celu realizacji swoich praw Użytkownik może w każdym czasie przesłać stosowaną wiadomość na adres poczty elektronicznej bądź w inny spos&oacute;b, kt&oacute;ry dostarczy/przekaże takie żądanie.&nbsp;</p>
    <p>3. Przetwarzanie danych osobowych os&oacute;b fizycznych będących naszymi klientami opiera się na:&nbsp;</p>
    <p>a) usprawiedliwionym interesie jako administratora danych (np. w zakresie tworzenia bazy danych, czynności analitycznych i profilujących, w tym czynności dot. analizy korzystania z produkt&oacute;w, marketingu bezpośredniego produkt&oacute;w własnych, zabezpieczeniu dokumentacji na potrzeby obrony przed ewentualnymi roszczeniami lub na potrzeby dochodzenia roszczeń)&nbsp;</p>
    <p>b) zgody (w tym w szczeg&oacute;lności zgody na e-mail marketing lub telemarketing)&nbsp;</p>
    <p>c) wykonania zawartej umowy&nbsp;</p>
    <p>d) obowiązk&oacute;w wynikających z prawa (np. prawa podatkowego lub przepis&oacute;w o rachunkowości).&nbsp;</p>
    <p>4. Przetwarzanie danych osobowych os&oacute;b fizycznych będących potencjalnymi klientami opiera się na:&nbsp;</p>
    <p>a) usprawiedliwionym interesie administratora danych (np. w zakresie tworzenia bazy danych, marketingu bezpośrednim produkt&oacute;w własnych)&nbsp;</p>
    <p>b) zgody (w tym w szczeg&oacute;lności zgody na e-mail marketing lub telemarketing)&nbsp;</p>
    <p>5. Żądanie ze strony Użytkownika usunięcia danych osobowych lub zaprzestania ich przetwarzania przez może skutkować całkowitym brakiem możliwości realizacji usług przez bądź ich poważnym ograniczeniem. &nbsp; &nbsp;</p>
    <p>6. Szczeg&oacute;lną wagę przykładamy do kwestii profilowania i wskazujemy, że:&nbsp;</p>
    <p>a) na potrzeby profilowania przetwarzamy z reguły dane, kt&oacute;re uprzednio podlegały szyfrowaniu ssl;&nbsp;</p>
    <p>b) wykorzystujemy do tego typowe dane: adres e-mail i IP lub cookies&nbsp;</p>
    <p>c) profilujemy w celu analizy lub prognozy osobistych preferencji oraz zainteresowań os&oacute;b korzystających z naszych Serwis&oacute;w lub produkt&oacute;w lub usług i dopasowywania treści znajdujących się w naszych Serwisach lub produktach do tych preferencji&nbsp;</p>
    <p>d) profilujemy w celach marketingowych, tj. dopasowania oferty marketingowej do ww. preferencji.&nbsp;</p>
    <p>7. Zobowiązujemy się postępować zgodnie z obowiązującymi przepisami prawa i zasadami wsp&oacute;łżycia społecznego.&nbsp;</p>
    <p>8. Informacja o pozasądowym rozpatrywaniu spor&oacute;w konsumenckich. Podmiotem uprawnionym w rozumieniu ustawy o pozasądowym rozpatrywaniu spor&oacute;w konsumenckich jest Rzecznik Finansowy, kt&oacute;rego adres strony internetowej jest następujący: <a data-fr-linked="true" href="//www.rf.gov.pl">www.rf.gov.pl</a>.&nbsp;</p>
    <p style="text-align: center;">&sect;6 Podstawowe zasady bezpieczeństwa&nbsp;</p>
    <p>1. Każdy użytkownik powinien dbać o swoje własne bezpieczeństwo danych oraz o bezpieczeństwo swoich urządzeń, kt&oacute;re służą dostępowi do sieci Internet. Takie urządzenie powinno bezwzględnie posiadać program antywirusowy z aktualną regularnie uzupełnianą bazą definicji, typ&oacute;w i rodzaj&oacute;w wirus&oacute;w, bezpieczną wersję przeglądarki internetowej z kt&oacute;rej korzysta oraz włączoną zaporę sieciową. Użytkownik powinien sprawdzać, czy system operacyjny i programy zainstalowane na nim posiadają najnowsze i kompatybilne aktualizacje, ponieważ w atakach wykorzystywane są błędy wykryte w zainstalowanym oprogramowaniu.&nbsp;</p>
    <p>2. Dane dostępowe do usług oferowanych w sieci Internet to &ndash; np. loginy, hasła, PIN, certyfikaty elektroniczne itp., &ndash; powinny być zabezpieczone w miejscu niedostępnym dla innych i niemożliwym do włamania z poziomu sieci internetowej. Nie należy ich ujawniać lub przechowywać na urządzeniu w formie, kt&oacute;ra umożliwia nieautoryzowany dostęp i odczyt przez osoby do tego nieuprawnione.&nbsp;</p>
    <p>3. Ostrożność podczas otwierania dziwnych załącznik&oacute;w lub klikania odnośnik&oacute;w w wiadomościach mailowych, kt&oacute;rych się nie spodziewaliśmy np. od nieznanych nadawc&oacute;w, bądź z folderu spam.&nbsp;</p>
    <p>4. Zaleca się uruchomienie w przeglądarce internetowej filtr&oacute;w antyphishingowych czyli narzędzi, kt&oacute;re sprawdzają, czy wyświetlona strona internetowa jest autentyczna i nie służy wyłudzaniu informacji, np. poprzez podszywanie się pod osobę lub instytucję.&nbsp;</p>
    <p>5. Pliki powinny być pobierane tylko i wyłącznie z zaufanych miejsc, serwis&oacute;w i stron. Nie zalecamy instalowania oprogramowania z niezweryfikowanych źr&oacute;deł zwłaszcza od nieznanych wydawc&oacute;w o niesprawdzonej opinii. Dotyczy to r&oacute;wnież urządzeń przenośnych, np. smartfon&oacute;w, tablet&oacute;w.&nbsp;</p>
    <p>6. Podczas używania domowej sieci bezprzewodowej Wi-Fi należy ustalić takie hasło aby było bezpieczne i trudne do złamania, nie powinno być nim żaden wz&oacute;r i ciąg znak&oacute;w kt&oacute;ry jest łatwy do odgadnięcia (np. nazwa ulicy, imię gospodarza, data urodzin itp.). Rekomenduje się także korzystanie najwyższych możliwych standard&oacute;w szyfrowania sieci bezprzewodowych Wi-Fi, kt&oacute;re są możliwe do uruchomienia na posiadanym sprzęcie np. WPA2. &nbsp;</p>
    <p style="text-align: center;">&sect;7 Korzystanie z wtyczek Social Media&nbsp;</p>
    <p>1. Wtyczki tak zwane plug-ins portali społecznościowych facebook.com i Twitter oraz innych, mogą znajdować się na naszych stronach. Związane z nimi usługi dostarczane są odpowiednio przez firmy Facebook Inc. i Twitter Inc. Aby&nbsp;</p>
    <p>2. Facebook obsługiwany jest przez Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA Facebook. zobaczyć wtyczki Facebook przejdź do: <a data-fr-linked="true" href="https://developers.facebook.com/docs/plugins">https://developers.facebook.com/docs/plugins</a>&nbsp;</p>
    <p>3. Twitter obsługiwany jest Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Aby zobaczyć wtyczki Twittera przejdź do: <a data-fr-linked="true" href="https://dev.twitter.com/web/tweet-button">https://dev.twitter.com/web/tweet-button</a>&nbsp;</p>
    <p>4. Wtyczka przekazuje jej dostawcy jedynie informację o tym, do kt&oacute;rych z naszych stron internetowych miałeś dostęp i w jakim czasie. Jeśli podczas oglądania naszej strony bądź przebywania na niej, użytkownik jest zalogowany do swojego konta znajdującego się np. na Facebooku lub Twitterze, dostawca jest w stanie łączyć Twoje zainteresowania, preferencję informacyjne, oraz inne dane, pozyskane np. poprzez kliknięcie przycisku Lubię to czy pozostawienie komentarza, bądź wpisanie nazwy profilu w wyszukiwanych. Taka informacja r&oacute;wnież zostanie przekazana przez przeglądarkę bezpośrednio do dostawcy.&nbsp;</p>
    <p>5. Więcej bardziej szczeg&oacute;łowych informacji na temat gromadzenia i wykorzystywania danych przez Facebook lub Twitter i na temat ochrony prywatności można znaleźć na poniższych stronach:</p>
    <p>&nbsp;a) Ochrona danych/porady dot. prywatności wydane przez Facebook: <a data-fr-linked="true" href="http://www.facebook.com/policy.php">http://www.facebook.com/policy.php</a>&nbsp;</p>
    <p>b) Ochrona danych/porady dot. prywatności wydane przez Twitter: <a data-fr-linked="true" href="https://twitter.com/privacy">https://twitter.com/privacy</a>&nbsp;</p>
    <p>6. Aby uniknąć odnotowania wizyty na wybranym koncie użytkownika przez Facebook lub Twitter na naszej stronie internetowej musisz wylogować się ze swojego konta przed rozpoczęciem przeglądania naszych stron internetowych.&nbsp;</p>
    <p style="text-align: center;">&sect;8 Dane z sieci społecznościowych&nbsp;</p>
    <p>Aby ułatwić proces rejestracji Aplikacja ora Witryna web może, za Twoją zgodą, uzyskiwać dostęp do podstawowych informacji o Twoim koncie w serwisie społecznościowym, takim jak, Google, w tym imienia i nazwiska, adresu e-mail, płci, urodzin, aktualnego miasta i adresu URL zdjęcia profilowego.&nbsp;</p>
    <p>Nota o prawach autorskich do Regulaminu&nbsp;</p>
    <p>Właścicielem wszystkich materialnych praw autorskich do wzorca niniejszej polityki jest Kancelaria Prawna LEGATO, kt&oacute;ra udzieliła niewyłącznego i niezbywalnego prawa do wykorzystywania tego dokumentu do cel&oacute;w związanych z własną działalnością handlową w Internecie oraz rozciąga ochronę prawną na w/w dokument na czas trwania umowy. Kopiowanie oraz rozpowszechnianie wzorca niniejszego dokumentu bez zgody Kancelarii Prawnej LEGATO jest zabronione i może podlegać odpowiedzialności zar&oacute;wno karnej jak i cywilnej. Sprzedawcy internetowi mogą dowiedzieć się więcej o możliwości korzystania z wzorca polityki prywatności i cookies na stronie http://www.kancelarialegato.pl &nbsp;</p>
    <p><br></p></div>
</template>

<script>
export default {
  name: 'Privacy.vue'
}
</script>

<style scoped>
  t {
    color: wheat;
  }
</style>
