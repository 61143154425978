import axios from 'axios'

export default {
  SAVE_ADMIN_NOTIFICATION_LIST (state, data) {
    state.notificationAdminData = data
  },
  SAVE_NOTIFICATION_LIST (state, data) {
    if (data) {
      if (data.length === 0) {
        state.notificationIncoming = false
      } else {
        state.notificationListData = data
        state.notificationIncoming = true
        state.notificationCount = data.length
      }
    } else {
      state.notificationIncoming = false
    }
  },
  DELETE_READ_NOTIFICATION (state, id) {
    const position = state.notificationListData.findIndex(element => element.id === id)
    state.notificationListData.splice(position, 1)
    state.notificationBlock = true
    state.notificationProcessing = true
  },
  ADD_TO_OFFLINE_STACK_STATS_MODULE_CLICKED (state, data) {
    const stackData = localStorage.getItem('offline_stack_moduleName2')
    if (stackData) {
      this.state.offline_stack_moduleName2 = JSON.parse(stackData)
    }
    let find = false
    this.state.offline_stack_moduleName2.forEach(e => {
      if (e.name === data.name && e.type === data.type) find = true
    })
    if (find) {
      for (let i = 0; i < state.offline_stack_moduleName2.length; i++) {
        if (state.offline_stack_moduleName2[i].name === data.name) {
          state.offline_stack_moduleName2[i].countToUpdate = state.offline_stack_moduleName2[i].countToUpdate + 1
          break
        }
      }
    } else {
      state.offline_stack_moduleName2.push(data)
    }
    localStorage.removeItem('offline_stack_moduleName2')
    localStorage.setItem('offline_stack_moduleName2', JSON.stringify(state.offline_stack_moduleName2))
  },
  EXECUTE_OFFLINE_STACK_MODULE_NAME (state) {
    if (navigator.onLine) {
      const stackData = localStorage.getItem('offline_stack_moduleName2')
      if (stackData) {
        const stackParsedData = JSON.parse(stackData)
        const payload = {
          moduleClickedList: stackParsedData
        }
        const headers = {
          'Content-Type': 'application/json',
          Authorization: state.develop ? state.api_key_to_stats_beta : state.api_key_to_stats
        }
        axios
          .put(state.path_api + '/stats/put/module_clicked', payload, { headers })
          .then(response => {
            if (response.status === 200 && response.data === 'Success update in table') {
              localStorage.removeItem('offline_stack_moduleName2')
            }
          })
      }
    }
  },
  SET_FAVORITES_OFFENSE_LIST (state, stringData) {
    localStorage.removeItem('favorites_offenses_array')
    const jsonData = JSON.parse(stringData)
    const offenceArray = jsonData
    state.favorites_offenses_array = offenceArray
    localStorage.setItem('favorites_offenses_array', stringData)
  },
  SET_BLOCKING (state) {
    const status = localStorage.getItem('BLOCKING')
    if (status) {
      state.block_ini_logo = true
    }
  },
  SET_FLAG_BLOCKING (state) {
    localStorage.setItem('BLOCKING', JSON.stringify('BLOCKING'))
    state.block_ini_logo = true
  },
  REMOVE_FLAG_BLOCKING (state) {
    localStorage.removeItem('BLOCKING')
    state.block_ini_logo = false
  },
  GET_PERMISSIONS (state) {
    const UID = localStorage.getItem('UID')
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': JSON.parse(UID)
    }
    if (UID) {
      if (navigator.onLine) {
        axios
          .get(state.path_api + '/permissions/get', { headers })
          .then(response => {
            state.permissions = response.data
            localStorage.removeItem('PERMISSIONS')
            localStorage.setItem('PERMISSIONS', JSON.stringify(state.permissions))
          })
      } else {
        const permissions = localStorage.getItem('PERMISSIONS')
        if (permissions) {
          state.permissions = JSON.parse(permissions)
        }
      }
    }
  },
  SET_LOGIN_DATA (state, bearer) {
    state.bearer = bearer
    localStorage.setItem('bearerToken', JSON.stringify(bearer))
    axios.defaults.headers.common.Authorization = `Bearer ${
      bearer
    }`
    state.isLogin = true
    const before = bearer.substring(bearer.indexOf('.') + 1)
    const after = before.slice(0, before.indexOf('.'))
    const tokenJson = JSON.parse(window.atob(after))
    console.log(tokenJson)
    if (tokenJson.role === 'Admin') {
      state.isAdmin = true
    }
    state.exp_token_date = tokenJson.exp
    const UID = localStorage.getItem('UID')
    if (UID) {
      const UID_PARSED = JSON.parse(UID)
      if (tokenJson.uid !== UID_PARSED) {
        state.UID = tokenJson.uid
        localStorage.setItem('UID', JSON.stringify(state.UID))
      }
    }
  },
  CLEAR_LOGIN_DATA (state) {
    console.log('remove data')
    localStorage.removeItem('bearerToken')
    state.isLogin = false
    window.location.reload()
  },
  CHANGE_NEW_LAW (state) {
    state.new_law = true
  },
  CHANGE_STATUS_NEW_LAW_SEARCH (state) {
    if (state.new_law_serach) state.new_law_serach = false
    else state.new_law_serach = true
  },
  GET_INSTALLATION_PARAM (state) {
    const UID = localStorage.getItem('UID')
    if (UID) {
      const UID_PARSED = JSON.parse(UID)
      state.UID = UID_PARSED
      if (navigator.onLine) {
        const headers = {
          'Content-Type': 'application/json',
          'INSTALLATION-UID': state.UID,
          Authorization: state.develop ? state.api_key_to_stats_beta : state.api_key_to_stats
        }
        axios
          .put(state.path_api + '/stats/put/last_cold_run', null, { headers })
          .then(response => {
            state.version = response.data.version_number
            localStorage.setItem('VERSION', JSON.stringify(state.version))
            const payload2 = {
              id: 0,
              uid: state.UID,
              user_agent: window.navigator.userAgent
            }
            const headers = {
              'Content-Type': 'application/json'
            }
            axios
              .post(state.path_api + '/installation/set_user_agent', payload2, { headers })
          })
          .catch(e => console.log(e))
      } else {
        const version = localStorage.getItem('VERSION')
        if (version) {
          const versionParsed = JSON.parse(version)
          state.version = versionParsed
        }
      }
    } else {
      if (navigator.onLine) {
        const headers = {
          'Content-Type': 'application/json'
        }
        axios
          .get(state.path_api + '/installation/get_UID', { headers })
          .then(response => {
            state.UID = response.data.uid
            localStorage.setItem('UID', JSON.stringify(state.UID))
            const headers = {
              'Content-Type': 'application/json',
              'INSTALLATION-UID': state.UID,
              Authorization: state.develop ? state.api_key_to_stats_beta : state.api_key_to_stats
            }
            axios
              .put(state.path_api + '/stats/put/last_cold_run', null, { headers })
              .then(response => {
                state.version = response.data.version_number
                localStorage.setItem('VERSION', JSON.stringify(state.version))
                const payload2 = {
                  id: 0,
                  uid: state.UID,
                  user_agent: window.navigator.userAgent
                }
                const headers = {
                  'Content-Type': 'application/json'
                }
                axios
                  .post(state.path_api + '/installation/set_user_agent', payload2, { headers })
              })
              .catch(e => console.log(e))
          })
      }
    }
  }
}
