<template>
  <div id="main">
    <div v-if="true" class="containerMy">
      <div class="element" @click="globalGoTo('/postal_code/', 'Kody pocztowe',null, 'HELPER')">
        <div class="icon"><i class="fas fa-envelope"></i></div>
        <div class="label">Kody pocztowe</div>
      </div>
      <div class="element" @click="globalGoTo('https://www.ufg.pl/infoportal/faces/pages_home-page/Page_4d98135c_14e2b8ace27__7ff1/Pagee0e22f3_14efe6adc05__7ff1/Page4d024e07_14f0a824115__7ff6?_afrLoop=3753003479910681&_afrWindowMode=0&_adf.ctrl-state=182qsvy3xd_29', 'Link do UFG', 'web', 'HELPER')">
        <div class="icon"><i class="fas fa-car-crash"></i></div>
        <div class="label">Link do sprawdzenia OC w UFG</div>
      </div>
      <div class="element" @click="globalGoTo('https://moj.gov.pl/uslugi/engine/ng/index?xFormsAppName=UprawnieniaKierowcow&xFormsOrigin=EXTERNAL','Link to sprawdzenia PJ', 'web', 'HELPER')">
        <div class="icon"><i class="fas fa-id-card"></i></div>
        <div class="label">Link do sprawdzenia uprawnień</div>
      </div>
      <div class="element" @click="globalGoTo('https://historiapojazdu.gov.pl/', 'Link do historii samochodu', 'web', 'HELPER')">
        <div class="icon"><i class="fas fa-history"></i></div>
        <div class="label">Link do historii pojazdu</div>
      </div>
      <div v-if="isLogin" class="element" @click="globalGoTo('/PdfPattern/', null)">
        <div class="icon"><i class="fas fa-clipboard-check"></i></div>
        <div class="label">Wzory Protokołów</div>
      </div>
      <div v-if="!isLogin" class="elementDisabled">
        <div class="icon"><i class="fas fa-clipboard-check"></i></div>
        <div class="label">Wzory Protokołów (zaloguj się)</div>
      </div>
      <div class="element" @click="globalGoTo('/Validation/', null)">
        <div class="icon"><i class="fas fa-clipboard-check"></i></div>
        <div class="label">Kontrola autentyczności dokumentów</div>
      </div>
<!--      <div class="element" @click="globalGoTo('/Points_Calc/','Kalkulator punktów STARY', null, 'HELPER')">-->
<!--        <div class="icon"><i class="fas fa-calculator"></i></div>-->
<!--        <div class="label">Kalkulator punktów STARY</div>-->
<!--      </div>-->
      <div class="element" @click="globalGoTo('https://ckt.uc.ost112.gov.pl/','Książka telefoniczna', 'web', 'HELPER')">
        <div class="icon"><i class="fas fa-tty"></i></div>
        <div class="label">Link do książki telefonicznej (Policja)</div>
      </div>
      <div v-if="isLogin" class="element" @click="globalGoTo('https://www.gov.pl/web/infrastruktura/ograniczenia-w-ruchu','Zakazy ciez', 'web', 'HELPER')">
        <div class="icon"><i class="fa-solid fa-ban"></i></div>
        <div class="label">Link do ograniczeń ruchu pow. 12t</div>
      </div>
      <div v-if="!isLogin" class="elementDisabled">
        <div class="icon"><i class="fa-solid fa-ban"></i></div>
        <div class="label">Link do ograniczeń ruchu pow. 12t (zaloguj się)</div>
      </div>
<!--      <div class="element" @click="globalGoTo('https://specbrands.pl/','SPECBRANDS', 'web', 'PARTNER')">-->
<!--        <div class="icon"><img :src="require(`@/assets/others/logo_black.png`)" alt="Sign"/></div>-->
<!--        <div class="label">Sklep mundurowy</div>-->
<!--      </div>-->
      <div class="element" @click="globalGoTo('/SPB/home/', null)">
        <div class="icon"><i class="fa-solid fa-gun"></i></div>
        <div class="label">ŚPB</div>
      </div>
      <div v-if="isLogin" class="element" @click="globalGoTo('/SpeedCalc/', 'Kalkulator prędkości', null, 'HELPER')">
        <div class="icon"><i class="fa-solid fa-gauge-high"></i></div>
        <div class="label">Kalkulator prędkości</div>
      </div>
      <div v-if="!isLogin" class="elementDisabled">
        <div class="icon"><i class="fa-solid fa-gauge-high"></i></div>
        <div class="label">Kalkulator prędkości (zaloguj się)</div>
      </div>
      <div class="element" @click="globalGoTo('/Note/', 'Notatnik', null, 'HELPER')">
        <div class="icon"><i class="fa-solid fa-clipboard"></i></div>
        <div class="label">Notatnik</div>
      </div>
      <div v-if="false" class="element" @click="globalGoTo('/Prewencjusz/', 'Prewencja pomoce', null, 'HELPER')">
        <div class="icon"><i class="fa-solid fa-bolt"></i></div>
        <div class="label">Prewencjusz</div>
      </div>
       <div class="element" @click="globalGoTo('/DrunkCalc/', 'Kalkulator trzeźwości', null, 'HELPER')">
        <div class="icon"><i class="fa-solid fa-calculator"></i></div>
        <div class="label">Przelicznik mg/l - ‰</div>
      </div>
<!--      <div class="element" @click="globalGoTo('https://holdtheline.pl/category/blog/','Link do HTL', 'web', 'PARTNER')">-->
<!--        <div class="icon"><i class="fa-solid fa-blog"></i></div>-->
<!--        <div class="label">Blog #HoldTheLine</div>-->
<!--      </div>-->
      <div class="element" v-if="isLogin" @click="globalGoTo('/EnglishMain/', 'Angielski', null, 'HELPER')">
        <div class="icon"><i class="fa-solid fa-language"></i></div>
        <div class="label">Słowniczek</div>
      </div>
      <div class="elementDisabled" v-if="!isLogin">
        <div class="icon"><i class="fa-solid fa-language"></i></div>
        <div class="label">Słowniczek</div>
        <div class="label">(zaloguj się)</div>
      </div>
      <div class="element" v-if="isLogin" @click="globalGoTo('https://healthcreator.pl/','HC', 'web', 'PARTNER')">
        <div class="icon"><img :src="require(`@/assets/others/logoHc.png`)" alt="Hc"/></div>
        <div class="label2">Kod rabatowy:</div>
        <div class="label2">Tapo24</div>
      </div>
      <div class="element" @click="globalGoTo('https://www.smart-target.pl/sklep/?par=3','Link do steveJobs', 'web', 'PARTNER')">
        <div class="icon"><i class="fa-solid fa-gun"></i></div>
        <div class="label">Sklep SmartTarget</div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Helpers.vue',
  data () {
    return {
      payload: {
        moduleName: null
      }
    }
  },
  methods: {
  },
  computed: {
    ...mapState([
      'isLogin'
    ])
  }
}
</script>

<style scoped lang="scss">
@import "src/views/grid";
img {
  width: 80px;
}
.img2 {
  width: 120px;
}
.label2 {
  font-size: 15px;
  text-align: center;
  font-weight: bold;
}
.element2 {
  color: #0e0e0e;
  background-color: rgb(253, 210, 60);
  height: 100px;
  padding: 3px 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-family: 'Readex Pro', sans-serif;
}
.elementDisabled {
  color: #0e0e0e;
  background-color: #3d597f;
  height: 100px;
  padding: 3px 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  font-family: 'Readex Pro', sans-serif;
}
</style>
