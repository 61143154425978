import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// Import Bootstrap an BootstrapVue CSS files (order is important)
Vue.config.productionTip = false
library.add(faHatWizard)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.mixin({
  data () {
    return {
      payload: {
        moduleName: null
      }
    }
  },
  methods: {
    catchErrorPage: function (error, procesName) {
      this.$store.state.error = error
      this.$store.state.proces_Name = procesName
      this.$store.state.error_response = error.response
      this.$store.state.error_message = error.response.data
      this.$router.push({ name: 'ErrorPage' })
    },
    convertTimestampToRRRMMDD: function (value) {
      const date = new Date(value * 1000)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = date.getDate()
      if (day < 10) {
        day = '0' + day
      }
      return year + '-' + month + '-' + day
    },
    globalGoTo: function (path, moduleName, typeLink, typeModuleToStats) {
      if (moduleName != null && typeModuleToStats != null) {
        this.statsModuleClicked(typeModuleToStats, moduleName)
      } else {
        console.log('Error GLOBAL GO TO')
        console.log('Path: ' + path)
        console.log('Module name: ' + moduleName)
        console.log('type link: ' + typeLink)
        console.log('type to module stats: ' + typeModuleToStats)
      }
      if (typeLink === 'web') {
        window.open(path)
      } else {
        this.$router.push({ path: path })
      }
    },
    statsModuleClicked: function (typeModuleToStats, moduleName) {
      if (navigator.onLine) {
        // online navigator
        const payload = {
          moduleClickedList: [{
            type: typeModuleToStats, // PDF, MENU, ROAD, HELPER, SOCIAL, SPB, PARTNER, UTO
            countToUpdate: 1,
            name: moduleName
          }]
        }
        const headers = {
          'Content-Type': 'application/json',
          Authorization: this.$store.state.develop ? this.$store.state.api_key_to_stats_beta : this.$store.state.api_key_to_stats
        }
        axios
          .put(this.$store.state.path_api + '/stats/put/module_clicked', payload, { headers })
      } else {
        const data = {
          type: typeModuleToStats, // PDF, MENU, ROAD, HELPER, SOCIAL, SPB, PARTNER, UTO
          countToUpdate: 1,
          name: moduleName
        }
        this.$store.commit('ADD_TO_OFFLINE_STACK_STATS_MODULE_CLICKED', data)
      }
    },
    vcs: function () {
      const headers = {
        'Content-Type': 'application/json'
      }
      axios
        .get(this.$store.state.path_api + '/version/get_last_versions', { headers })
        .then(response => {
          if (this.$store.state.version !== response.data.version_number && this.$store.state.version) {
            if (response.data.force_update === true) {
              alert('Na serwerze produkcyjnym znaleziono nowszą wersję aplikacji, która jest wdrożona automatycznie. Nastąpi restart aplikacji')
              setTimeout(this.force_update, 3000)
            } else {
              if (response.data.date_force_update === 0) {
                if (this.$store.state.alert_show === false) {
                  alert('Na serwerze produkcyjnym znaleziono nowszą wersję aplikacji, aby ją pobrać zrestartuj aplikację (zakończ działanie aplikacji w tle)')
                  this.$store.state.alert_show = true
                }
              } else {
                const current = new Date()
                const timestamp = response.data.date_force_update * 1000
                const dateUpdate = new Date(timestamp)
                if (timestamp > current.getTime()) {
                  if (this.$store.state.alert_show === false) {
                    alert('Na serwerze produkcyjnym znaleziono nowszą wersję aplikacji, aby ją pobrać zrestartuj aplikację (zakończ działanie aplikacji w tle). UWAGA: auto aktualizacja nastąpi:' + dateUpdate.toLocaleString('pl-PL', { weekday: 'long' }) + dateUpdate.toLocaleString('pl-PL'))
                  }
                  this.$store.state.alert_show = true
                } else {
                  alert('Na serwerze produkcyjnym znaleziono nowszą wersję aplikacji, która jest wdrożona automatycznie. Nastąpi restart aplikacji')
                  this.force_update()
                }
              }
            }
          }
        })
    },
    update_last_run: function () {
      if (this.$store.state.UID != null) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: this.$store.state.develop ? this.$store.state.api_key_to_stats_beta : this.$store.state.api_key_to_stats,
          'INSTALLATION-UID': this.$store.state.UID
        }
        axios
          .put(this.$store.state.path_api + '/stats/put/last_run', null, { headers })
      }
    },
    force_update: function () {
      window.location.reload(true)
    }
  }
})
Vue.filter('date', function (value) {
  if (!value) return ''
  if (value) {
    const date = new Date(value * 1000)
    const year = date.getFullYear()
    let month = date.getMonth() + 1
    if (month < 10) {
      month = '0' + month
    }
    let day = date.getDate()
    if (day < 10) {
      day = '0' + day
    }
    let hour = date.getHours()
    if (hour < 10) {
      hour = '0' + hour
    }
    let minute = date.getMinutes()
    if (minute < 10) {
      minute = '0' + minute
    }
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute
  }
  return ''
})
new Vue({
  router,
  store,
  created () {
    // above barrier check and generate new TODO
    this.$store.commit('GET_PERMISSIONS')
    this.$store.dispatch('check_validate_token_on_start_and_load_to_service').then(data => {
      if (data) {
        this.$store.dispatch('load_favorites_offenses')
      }
    }
    )
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 401 && this.$route.name !== 'Login') {
          this.$store.dispatch('logout_from_service')
        }
        console.log('EEEEE')
        console.log(error.response)
        return Promise.reject(error)
      }
    )
  },
  render: h => h(App)
}).$mount('#app')
Vue.config.errorHandler = (err, vm, info) => {
  // err: error trace
  // vm: component in which error occured
  // info: Vue specific error information such as lifecycle hooks, events etc.
  console.log('error')
  console.log(err, vm, info)
  // TODO: Perform any custom logic or log to server
}
