<template>
  <div class="main">
  <div>
  <div class="small_main_container">
    <div class="group_element">
      <div class="text">
        Nazwa użytkownika lub email
      </div>
      <div class="input_box">
        <input placeholder="Wprowadź login lub hasło" v-model="credentials.login" id="login1">
      </div>
    </div>
    <div class="group_element">
      <div class="password_and_forgot">
        <div>Hasło</div>
        <div class="forgot" @click="click_to_password_recovery">Zapomniałeś hasła? <i class="fa-regular fa-hand-pointer"></i></div>
      </div>
      <div class="input_box">
        <input placeholder="Wprowadź hasło" v-model="credentials.password" type="password" id="password1">
      </div>
    </div>
    <div class="" id="errorDescBox">{{erorrDesc}}</div>
    <div class="orange_button" @click="click_to_login">Zaloguj  <i class="fa-solid fa-right-to-bracket"></i></div>
  </div>
  <div class="footer" @click="click_to_register">
    Nowy w TaPo24.pl Załóż konto <i class="fa-regular fa-hand-pointer"></i>
  </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  data () {
    return {
      credentials: {
        login: '',
        password: ''
      },
      erorrDesc: ''
    }
  },
  methods: {
    click_to_login (event) {
      this.$store.dispatch('login_to_service', {
        login: this.credentials.login.trim(),
        password: this.credentials.password
      }).catch(error => {
        document.getElementById('password1').className = ''
        document.getElementById('password1').className = 'test'
        document.getElementById('login1').className = ''
        document.getElementById('login1').className = 'test'
        if (error.response.data === 'Incorrect password') {
          document.getElementById('errorDescBox').className = 'error_Text'
          this.erorrDesc = 'Wprowadziłeś nie poprawne dane do logowania'
        }
        if (error.response.data === 'User not found') {
          document.getElementById('errorDescBox').className = 'error_Text'
          this.erorrDesc = 'Brak takiego konta w serwisie'
        }
        if (error.response.data === 'User haven\'t confirmed email') {
          document.getElementById('errorDescBox').className = 'error_Text'
          this.erorrDesc = 'Konto nie jest aktywowane'
        }
        if (error.response.data === 'User have not activated account') {
          document.getElementById('errorDescBox').className = 'error_Text'
          this.erorrDesc = 'Konto nie jest aktywowane'
        }
        if (error.response.data === 'User has been disabled') {
          document.getElementById('errorDescBox').className = 'error_Text'
          this.erorrDesc = 'Konto nie jest aktywowane'
        }
        if (error.response.data === 'User was banned') {
          document.getElementById('errorDescBox').className = 'error_Text'
          this.erorrDesc = 'Użytkownik jest zbanowany'
        }
        setTimeout(this.clear_error, 1500)
      }).then(data => {
        if (data) {
          console.log('DATA NOW')
          this.$store.dispatch('load_favorites_offenses')
          this.$router.push({ name: 'LoginSuccess' })
        }
      })
    },
    clear_error () {
      document.getElementById('password1').className = ''
      document.getElementById('login1').className = ''
      document.getElementById('errorDescBox').className = ''
      this.erorrDesc = ''
    },
    click_to_register () {
      this.$router.push({ name: 'Register' })
    },
    click_to_password_recovery () {
      this.$router.push({ name: 'PasswordRecovery' })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/main_layout";
@import "src/views/others";

.main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.test {
  background: #ff0000;
  border: 5px solid #bd0000;
  border-radius: 5px;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.error_Text{
  border-radius: 5px;
  width: 100%;
  background: #ff0000;
  margin: 10px 0px 10px 0px;
  padding: 5px 0 5px 0px;
  color: #0e0e0e;
  font-family: $text-family;
  font-weight: bold;
  align-content: center;
  text-align: center;
}
.password_and_forgot {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.forgot:hover {
  cursor: pointer;
  background-color: #9cbad3;
  border-radius: 8px;
}
.forgot{
  background-color: rgba(41, 50, 65, 0.43);
  border-radius: 8px;
  transition: 0.5s;
}
.orange_button {
  width: auto;
  justify-content: center;
}
input{
  max-width: 100%;
  border-width: 0;
  border-radius: 5px;
  padding: 4px 10px;
}
.group_element{
  font-family: $text-family;
  font-weight: bold;
}
.input_box{
  display: flex;
  margin-bottom: 20px;
}
.text {
  margin-bottom: 10px;
}
.footer{
  margin: 0 10px;
  width: auto;
  background-color: #3D5A80;
  border-radius: 8px;
  padding: 20px 10px;
  height: min-content;
  font-family: $text-family;
  font-weight: bold;
  text-align: center;
  transition: 0.5s;
}
.footer:hover {
  cursor: pointer;
  background-color: #9cbad3;
}
.small_main_container{
  margin: 20px 10px 10px 10px;
}
</style>
