<template>
  <div id="app">
    <div id="login_screen" v-if="!login_form && $route.name==='Home'">
<!--      <video class="video" autoplay muted>-->
<!--        <source :src="require(`@/assets/others/video.mp4`)" type="video/mp4">-->
<!--      </video>-->
    </div>
    <navBar class="navbarMy" v-if="(login_form || $route.name!=='Home')
    && ($route.name!=='ActivateAccount' && $route.name!=='RemoveAccount'
    && $route.name!=='UnsubscribeAdv'
    && $route.name!=='ResendToken' || uidExist)"/>
    <div id="view" v-if="login_form || $route.name!=='Home'">
      <router-view/>
      <div class="notificationSystem" v-if="login_form && notificationIncoming && $route.name!=='NotificationPage'" @click="click_on_notification">
        <div class="notificationCointairner">
          <div class="iconNotification"><i class="fa-solid fa-message fa-bounce"></i></div>
          <div class="notificationText">Masz {{ notificationCount }} wiadomości</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import navBar from '@/components/navBar'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    navBar
  },
  data () {
    return {
      timer: null,
      login_form: false,
      change_law_date: 1663365599,
      uidExist: false
    }
  },
  computed: {
    ...mapState([
      'block_ini_logo',
      'permissions',
      'notificationCount',
      'notificationIncoming',
      'notificationBlock'
    ])
  },
  created () {
    console.log('RUN TEST PATH')
    console.log(window.location.host)
    this.$store.commit('SET_BLOCKING')
    if (this.$route.name === 'Home') {
      this.$store.commit('GET_INSTALLATION_PARAM')
      this.uidExist = true
    }
    const UID = localStorage.getItem('UID')
    if (UID) {
      this.$store.commit('GET_INSTALLATION_PARAM')
      this.uidExist = true
    }
  },
  mounted () {
    window.addEventListener('error', (event) => {
      console.log('WINDOW ERROR  ')
      console.log(event.type)
      console.log(event.message)
    })
    window.onerror = function (message, source, lineno, colno, error) {
      // TODO: write any custom logic or logs the error
      console.log('ERROR')
      console.log(message)
      console.log(source)
      console.log(lineno)
      console.log(colno)
      console.log(error)
    }
    let permissions = localStorage.getItem('PERMISSIONS')
    if (permissions) {
      permissions = JSON.parse(permissions)
      if (permissions.skipIntro === true) {
        this.show_app()
      } else {
        if (this.block_ini_logo) {
          this.show_app()
        } else setTimeout(this.show_app, 4001)
      }
    } else {
      if (this.block_ini_logo) {
        this.show_app()
      } else setTimeout(this.show_app, 4001)
    }
    const nav = document.querySelector('.navbarMy')
    const searchBox = document.querySelector('#search_top_bar2')
    let lastScrollY = window.scrollY
    if (searchBox != null) {
      window.addEventListener('scroll', () => {
        if (window.scrollY > lastScrollY + 50 || window.scrollY < lastScrollY - 50) {
          if (lastScrollY > 70) {
            if (lastScrollY < window.scrollY) {
              nav.classList.add('navbar--hidden')
              searchBox.classList.add('search_top_bar2--hidden')
            } else {
              this.clear_timeout()
              nav.classList.remove('navbar--hidden')
              searchBox.classList.remove('search_top_bar2--hidden')
              this.timer = setTimeout(this.hide_top_bar, 2000)
            }
          }
          lastScrollY = window.scrollY
        }
      })
    }
  },
  methods: {
    click_on_notification () {
      this.$store.state.notificationBlock = true
      this.$store.state.notificationProcessing = false
      this.$router.push({ name: 'NotificationPage' })
    },
    hide_top_bar () {
      const nav = document.querySelector('.navbarMy')
      const searchBox = document.querySelector('#search_top_bar2')
      if (window.scrollY > 70) {
        nav.classList.add('navbar--hidden')
        searchBox.classList.add('search_top_bar2--hidden')
      }
    },
    clear_timeout () {
      window.clearTimeout(this.timer)
    },
    remove_blocking () {
      this.$store.commit('REMOVE_FLAG_BLOCKING')
    },
    show_app () {
      this.$store.commit('SET_FLAG_BLOCKING')
      setTimeout(this.remove_blocking, 1)
      this.login_form = true
      document.getElementsByTagName('body')[0].style.height = 'unset'
    }
  },
  updated () {
    if (navigator.onLine) {
      const current = new Date()
      const currentDate = current.getTime()
      const expDate = this.$store.state.exp_token_date
      if (expDate !== 0 && this.$store.state.isLogin) {
        if (currentDate > (expDate - 12 * 24 * 60 * 60) * 1000) {
          this.$store.dispatch('get_new_jwt_token')
        }
      }
      this.vcs()
      this.update_last_run()
      if (!this.notificationBlock) {
        this.$store.dispatch('get_notification_list')
      }
      this.$store.commit('EXECUTE_OFFLINE_STACK_MODULE_NAME')
    }
    // new law checker change date
    const current = new Date()
    if (this.change_law_date * 1000 < current.getTime()) this.$store.commit('CHANGE_NEW_LAW')
    // do bartka co by nie marudził
    const nav = document.querySelector('.navbarMy')
    const searchBox = document.querySelector('#search_top_bar2')
    let lastScrollY = window.scrollY
    if (searchBox != null) {
      window.addEventListener('scroll', () => {
        if (window.scrollY > lastScrollY + 50 || window.scrollY < lastScrollY - 50) {
          if (lastScrollY > 70) {
            if (lastScrollY < window.scrollY) {
              nav.classList.add('navbar--hidden')
              searchBox.classList.add('search_top_bar2--hidden')
            } else {
              this.clear_timeout()
              nav.classList.remove('navbar--hidden')
              searchBox.classList.remove('search_top_bar2--hidden')
              this.timer = setTimeout(this.hide_top_bar, 2000)
            }
          }
          lastScrollY = window.scrollY
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@/views/main_layout.scss";
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@300&display=swap');
* {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
*:before,
*:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.notificationSystem{
  background: rgba(41, 50, 65, 0.7);
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  border-radius: 10px;
  width: min-content;
  height: min-content;
  display: flex;
  float: right;
  align-items: flex-end;
  justify-content: flex-end;
  right: 0;
}
.notificationCointairner{
  align-items: center;
  text-align: center;
  padding: 10px;
}
.iconNotification{
  font-size: 50px;
  color: #EE6C4D;
}
.notificationText {
  color: #fffafa;
  font-family: $text-family !important;
  font-size: 15px;
}
html, body {
  font-family: $text-family !important;
  background-color: #293241;
  padding: 0;
  margin: 0;
  overscroll-behavior-y: contain;
  --nav-height: 112px;
  height: 100%;
  line-height: unset;
  font-size: unset;
  font-weight: unset;
  text-align: unset;
}
#app {
  background-color: #293241;
  height: 100%;
}
#login_screen {
  background-color: #293241;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
video{
  height: 100%;
}
.navbarMy {
  position: fixed;
  top: 0;
  z-index: 10;
  transition: transform 0.2s;
}
.navbar--hidden {
  transform: translateY(-125px);
  box-shadow: none;
}
#view {
  transition: 0.2s;
  margin-top: 120px;
}
#search_top_bar2 {
  background-color: #293241b7;
  position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */
  padding: 15px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: transform 0.2s;
}
.search_top_bar2--hidden {
  transform: translateY(-125px);
  box-shadow: none;
 }
@media only screen and (max-width: 560px){
  #search_top_bar2 {
    top: 125px;
    border-radius: 15px;
  }
  .notificationText{
    font-size: 10px;
  }
}
@media only screen and (min-width: 560px) {
  #search_top_bar2 {
  top: 125px;
  border-radius: 15px;
  }
  #view {
    margin-top: 120px !important;
    float: none !important;
    padding: 15px 0 15px;
  }
}
</style>
