<template>
  <div>
    <div id="pdf_container">
      <div class="top_bar">
        <div class="close" @click="close_pdf">Zamknij <i class="fas fa-times"></i></div>
      </div>
      <div id="adobe-dc-view"></div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'PdfViewer',
  data () {
    return {
      development: false,
      adobe_key: '2023be3e7c604da78217a23dda3c8229',
      adobe_key_dev: '7c43e907d7a144f181e76e77153f738b',
      adobeApiReady: false,
      master_path: 'https://tapo24.pl/static/',
      master_path_dev: 'https://beta.tapo24.pl/static/'
    }
  },
  methods: {
    close_pdf () {
      document.getElementById('pdf_container').style.visibility = 'hidden'
      this.$store.state.open_pdf = false
      if (this.$route.params.from === 'law') {
        this.$router.push({ name: 'Law' })
      }
      if (this.$route.params.from === 'PdfPattern') {
        this.$router.push({ name: 'PdfPattern' })
      }
      if (this.$route.params.from === 'About') {
        this.$router.push({ name: 'About' })
      }
      if (this.$route.params.from === 'Register') {
        this.$router.push({ name: 'Register' })
      }
    },
    gen_pdf () {
      this.statsModuleClicked('PDF', this.$route.params.pdf_name)
      if (this.development) {
        // dev
        this.adobeDCView = new window.AdobeDC.View({ clientId: this.adobe_key_dev, divId: 'adobe-dc-view' })
        this.adobeDCView.previewFile({
          content: { location: { url: this.master_path_dev + this.$route.params.pdf_path } },
          metaData: { fileName: this.$route.params.pdf_name }
        }, { embedMode: 'FULL_WINDOW', showLeftHandPanel: true })
      } else {
        // non devs
        this.adobeDCView = new window.AdobeDC.View({ clientId: this.adobe_key, divId: 'adobe-dc-view' })
        this.adobeDCView.previewFile({
          content: { location: { url: this.master_path + this.$route.params.pdf_path } },
          metaData: { fileName: this.$route.params.pdf_name }
        }, { embedMode: 'FULL_WINDOW', showLeftHandPanel: true })
      }
      document.getElementById('pdf_container').style.visibility = 'visible'
      this.$store.state.open_pdf = true
    }
  },
  mounted () {
    this.development = window.location.host.includes('beta')
    const AdobeDC = document.createElement('script')
    AdobeDC.setAttribute('src', 'https://documentcloud.adobe.com/view-sdk/main.js')
    document.head.appendChild(AdobeDC)
    document.addEventListener('adobe_dc_view_sdk.ready', () => {
      this.adobeApiReady = true
      this.$store.state.load_api_pdf = true
      this.gen_pdf()
    })
  }
}
</script>

<style scoped lang="scss">
#pdf_container {
  overflow: hidden;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  background-color: #3D5A80;
}
.top_bar {
  background-color: #3D5A80;
  display: flex;
  justify-content: right;
}
.close {
  padding: 10px 10px;
  font-family: 'Readex Pro', sans-serif;
}
.close:hover {
  background-color: #98C1D9;
}

</style>
