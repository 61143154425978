<template>
  <div></div>
</template>

<script>
export default {
  name: 'FilePublisher',
  props: {
    file: String
  },
  mounted () {
    window.open(this.file)
  }
}
</script>
