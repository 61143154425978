<template>
  <div id="main">
    <div v-if="true" class="containerMy">
      <div class="element" @click="globalGoTo('/sign/', 'Znaki', null, 'ROAD')">
        <div class="icon"><i class="fas fa-map-signs"></i></div>
        <div class="label">Znaki drogowe</div>
      </div>
      <div class="element" @click="globalGoTo('/TypeAccident/', 'Kolizja', null, 'ROAD')">
        <div class="icon"><i class="fas fa-car-crash"></i></div>
        <div class="label">Kwalifikacja zdarzenia</div>
      </div>
      <div class="element" @click="globalGoTo('/HomeAlcohol/', 'Alcohol', null, 'ROAD')">
        <div class="icon"><i class="fa-solid fa-wine-glass"></i></div>
        <div class="label">Kontrola trzeźwości</div>
      </div>
      <div class="element" @click="globalGoTo('/ControlList/', 'Kody usterek', null, 'ROAD')">
        <div class="icon"><i class="fas fa-exclamation-triangle"></i></div>
        <div class="label">Kody usterek</div>
      </div>
      <div class="element" @click="globalGoTo('/DrivingLicence/', 'Kategorie PJ', null, 'ROAD')">
        <div class="icon"><i class="fas fa-id-card"></i></div>
        <div class="label">Kategorie prawa jazdy</div>
      </div>
      <div class="element" @click="globalGoTo('/DrivingLicenceCode/', 'Kody ogr. PJ', null, 'ROAD')">
        <div class="icon"><i class="fas fa-glasses"></i></div>
        <div class="label">Kody ograniczeń prawa jazdy</div>
      </div>
      <div class="element" @click="globalGoTo('/DrivingLicenceCountry/', 'PJ zag', null, 'ROAD')">
        <div class="icon"><i class="fa-solid fa-flag"></i></div>
        <div class="label">Honorowane zagraniczne PJ </div>
      </div>
      <div class="element" @click="globalGoTo('/DocumentHolding/', 'Zatrzymywanie dok.', null, 'ROAD')">
        <div class="icon"><i class="fas fa-hand-holding"></i></div>
        <div class="label">Zatrzymywanie dokumentów</div>
      </div>
      <div class="element" @click="globalGoTo('/KSIPCode/', 'KSIP kody', null, 'ROAD')">
        <div class="icon"><i class="fas fa-info"></i></div>
        <div class="label">Statusy Prawa Jazdy KSIP</div>
      </div>
      <div class="element" @click="globalGoTo('/Lights/', 'Światła', null, 'ROAD')">
        <div class="icon"><i class="fas fa-lightbulb"></i></div>
        <div class="label">Oświetlenie pojazdu</div>
      </div>
      <div class="element" @click="globalGoTo('/Towing/', 'Holowanie', null, 'ROAD')">
        <div class="icon"><i class="fas fa-truck-loading"></i></div>
        <div class="label">Holowanie pojazdu</div>
      </div>
      <div class="element" @click="globalGoTo('/UTO/home/', null)">
        <div class="icon"><i class="fa-brands fa-accessible-icon"></i></div>
        <div class="label">UTO</div>
      </div>
      <div class="element" @click="globalGoTo('/RoadTransportHome/', 'Transport drogowy', null, 'ROAD')">
        <div class="icon"><i class="fa-solid fa-truck-fast"></i></div>
        <div class="label">Transport drogowy</div>
      </div>
      <div class="element" @click="globalGoTo('/CodeList/', 'Kody czynów', null, 'ROAD')">
        <div class="icon"><i class="fa-solid fa-circle-exclamation"></i></div>
        <div class="label">Kody czynów </div>
      </div>
      <div class="element" @click="globalGoTo('/recidivism/', 'Recydywa', null, 'ROAD')">
        <div class="icon"><i class="fa-solid fa-repeat"></i></div>
        <div class="label">Recydywa </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Road.vue',
  data () {
    return {
    }
  },
  methods: {
    goto (path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/grid";

</style>
