import axios from 'axios'

export default {
  get_user_info ({ commit }) {
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.state.UID
    }
    if (navigator.onLine) {
      return axios.get(this.state.path_api + '/profile/get_user_info', { headers })
        .then(({ data }) => {
          return data
        })
    }
  },
  delete_admin_notification ({ commit }, data) {
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.state.UID
    }
    return axios.delete(this.state.path_api + '/notify/admin/notification', { data: data }, { headers })
      .then(({ data }) => {
        return data
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  get_notification_list ({ commit }) {
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.state.UID
    }
    axios.get(this.state.path_api + '/notify/notifications', { headers })
      .then(({ data }) => {
        commit('SAVE_NOTIFICATION_LIST', data)
      })
  },
  get_new_jwt_token ({ commit }) {
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.state.UID
    }
    if (this.state.isLogin) {
      axios.post(this.state.path_api + '/login/generate_new_JWT', null, { headers })
        .then(r => {
          commit('SET_LOGIN_DATA', r.data)
        })
    }
  },
  mark_as_read_notification ({ commit }, id) {
    commit('DELETE_READ_NOTIFICATION', id)
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.state.UID
    }
    const data = {
      id: id
    }
    return axios.delete(this.state.path_api + '/notify/read_notification', { data: data, headers: headers })
      .then(({ data }) => {
        return data
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  get_admin_notification_list ({ commit }) {
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.state.UID
    }
    return axios.get(this.state.path_api + '/notify/admin/notifications', { headers })
      .then(({ data }) => {
        commit('SAVE_ADMIN_NOTIFICATION_LIST', data)
        return data
      })
  },
  get_admin_notification_by_id ({ commit }, id) {
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.state.UID
    }
    return axios.get(this.state.path_api + '/notify/admin/notification_by_id/?id=' + id, { headers })
      .then(({ data }) => {
        return data
      })
  },
  put_modify_notification ({ commit }, payload) {
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.state.UID
    }
    return axios.put(this.state.path_api + '/notify/admin/update_notification', payload, { headers })
      .then(({ data }) => {
        return data
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  post_addNotification ({ commit }, payload) {
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.state.UID
    }
    return axios.post(this.state.path_api + '/notify/admin/notification', payload, { headers })
      .then(({ data }) => {
        return data
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  post_generate_list ({ commit }, payload) {
    const headers = {
      'Content-Type': 'application/json',
      'INSTALLATION-UID': this.state.UID
    }
    return axios.post(this.state.path_api + '/notify/admin/generateNotificationList', payload, { headers })
      .then(({ data }) => {
        return data
      })
      .catch(error => {
        return Promise.reject(error)
      })
  },
  add_favorite_offense ({ commit }, offenseId) {
    const headers = {
      'Content-Type': 'application/json'
    }
    if (this.state.favorites_offenses_array.indexOf(offenseId) === -1) {
      this.state.favorites_offenses_array.push(offenseId)
    }
    if (navigator.onLine && this.state.isLogin) {
      const payload = {
        offensesList: JSON.stringify(this.state.favorites_offenses_array)
      }
      axios.put(this.state.path_api + '/profile/favorites_offenses', payload, { headers })
        .then(({ data }) => {
          localStorage.removeItem('favorites_offenses_to_send')
        })
    } else {
      localStorage.setItem('favorites_offenses_to_send', 'true')
    }
    commit('SET_FAVORITES_OFFENSE_LIST', JSON.stringify(this.state.favorites_offenses_array))
  },
  remove_favorite_offense ({ commit }, offenseId) {
    const headers = {
      'Content-Type': 'application/json'
    }
    const index = this.state.favorites_offenses_array.indexOf(offenseId)
    if (index !== -1) {
      this.state.favorites_offenses_array.splice(index, 1)
    }
    if (navigator.onLine && this.state.isLogin) {
      const payload = {
        offensesList: JSON.stringify(this.state.favorites_offenses_array)
      }
      axios.put(this.state.path_api + '/profile/favorites_offenses', payload, { headers })
        .then(({ data }) => {
          localStorage.removeItem('favorites_offenses_to_send')
        })
    } else {
      localStorage.setItem('favorites_offenses_to_send', 'true')
    }
    commit('SET_FAVORITES_OFFENSE_LIST', JSON.stringify(this.state.favorites_offenses_array))
  },
  load_favorites_offenses ({ commit }) {
    const headers = {
      'Content-Type': 'application/json'
    }
    // MIGRATION FUNCTION
    // const offenceArrayOldArray = JSON.parse(localStorage.getItem('favorites_array'))
    // if (offenceArrayOldArray) {
    //   localStorage.setItem('favorites_offenses_to_send', 'true')
    //   const newTariffArray = []
    //   offenceArrayOldArray.forEach(element => {
    //     const newElement = tariffData.tariff_array.find(dataElement => dataElement.name === element)
    //     newTariffArray.push(newElement.id)
    //   })
    //   commit('SET_FAVORITES_OFFENSE_LIST', JSON.stringify(newTariffArray))
    //   localStorage.removeItem('favorites_array')
    // }
    // NORMAL FUNCTION
    const toSendStatus = localStorage.getItem('favorites_offenses_to_send')
    if (toSendStatus) {
      if (toSendStatus === 'true') {
        const offenceArrayString = localStorage.getItem('favorites_offenses_array')
        if (navigator.onLine && this.state.isLogin && ((offenceArrayString !== null) && offenceArrayString !== 'null')) {
          const payload = {
            offensesList: offenceArrayString
          }
          axios.put(this.state.path_api + '/profile/favorites_offenses', payload, { headers })
            .then(({ data }) => {
              localStorage.removeItem('favorites_offenses_to_send')
            })
        }
        if ((offenceArrayString !== null) && offenceArrayString !== 'null') {
          commit('SET_FAVORITES_OFFENSE_LIST', offenceArrayString)
        }
      }
    } else {
      if (navigator.onLine && this.state.isLogin) {
        axios.get(this.state.path_api + '/profile/favorites_offenses', { headers })
          .then(({ data }) => {
            commit('SET_FAVORITES_OFFENSE_LIST', data.offensesList)
          })
      } else {
        const offenceArrayString = localStorage.getItem('favorites_offenses_array')
        if ((offenceArrayString !== null) && offenceArrayString !== 'null') {
          commit('SET_FAVORITES_OFFENSE_LIST', offenceArrayString)
        }
      }
    }
  },
  save_favorites_offenses ({ commit }) {
  },
  login_to_service ({ commit }, credentials) {
    const headers = {
      'Content-Type': 'application/json',
      // 'INSTALLATION-UID': this.state.UID
      'INSTALLATION-UID': this.state.UID
    }
    if (this.state.UID) {
      if (navigator.onLine) {
        return axios.post(this.state.path_api + '/login/normal_login_user', credentials, { headers })
          .then(({ data }) => {
            console.log('pased' + data)
            commit('SET_LOGIN_DATA', data)
            return data
          })
      }
    }
  },
  logout_from_service ({ commit }) {
    commit('CLEAR_LOGIN_DATA')
    // TODO: SEND TO LOGING SERVICE IN BACKEND THIS EVENT!
  },
  async  check_validate_token_on_start_and_load_to_service ({ commit }) {
    const jwtTokenFromStorage = localStorage.getItem('bearerToken')
    if (jwtTokenFromStorage) {
      const userTokenJwt = JSON.parse(jwtTokenFromStorage)
      const headers = {
        'Content-Type': 'application/json',
        'INSTALLATION-UID': this.state.UID,
        Authorization: 'Bearer ' + userTokenJwt
      }
      return await axios.get(this.state.path_api + '/login/test_token_valid_and_account_baned_status', { headers })
        .then(response => {
          // SUCCES
          commit('SET_LOGIN_DATA', userTokenJwt)
          return 'true'
        })
        .catch(error => {
          console.log('ERROR TEST !!!' + error)
          commit('CLEAR_LOGIN_DATA')
          return 'false'
        })
    }
    return 'false'
  }
}
