<template>
  <div class="small_main_container" id="form">
    <div class="group_element">
      <div class="text">
        Wprowadź email
      </div>
      <div class="grid">
        <div :class="[!validator.email && data.email === '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-question"></i>
        </div>
        <div :class="[validator.email && data.email === '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-question"></i>
        </div>
        <div :class="[validator.email && data.email !== '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-check" style="color: #42b983"></i>
        </div>
        <div :class="[!validator.email && data.email !== '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-xmark" style="color: #ea0c68"></i>
        </div>
        <input type="email" v-model=data.email placeholder="Wprowadź adres email" @change=valid_email id="email_input" @keydown.enter="keypress_email">
        <div class="hint_Text">{{ hint.email}}</div>
      </div>
    </div>
    <div :class="[validator.email ? 'group_element' : 'none']">
      <div class="text">
        Wprowadź login
      </div>
      <div class="grid">
        <div :class="[!validator.login && data.login === '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-question"></i>
        </div>
        <div :class="[validator.login && data.login === '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-question"></i>
        </div>
        <div :class="[validator.login && data.login !== '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-check" style="color: #42b983"></i>
        </div>
        <div :class="[!validator.login && data.login !== '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-xmark" style="color: #ea0c68"></i>
        </div>
        <input placeholder="Wprowadź swój login" v-model="data.login" @change=valid_login id="login_input" @keydown.enter="keypress_login">
        <div class="hint_Text">{{ hint.login}}</div>
      </div>
    </div>
    <div :class="[validator.login ? 'group_element' : 'none']">
      <div class="text">
        Wprowadź hasło
      </div>
      <div class="grid">
        <div :class="[!validator.password && data.password === '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-question"></i>
        </div>
        <div :class="[validator.password && data.password === '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-question"></i>
        </div>
        <div :class="[validator.password && data.password !== '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-check" style="color: #42b983"></i>
        </div>
        <div :class="[!validator.password && data.password !== '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-xmark" style="color: #ea0c68"></i>
        </div>
        <input placeholder="Wprowadź hasło" type="password"
               @change="valid_password" id="password" v-model="data.password" @keydown.enter="keypress_password">
        <div class="hint_Text">{{ hint.password}}</div>
      </div>
    </div>
    <div :class="[validator.password ? 'group_element' : 'none']">
      <div class="text">
        Powtórz hasło
      </div>
      <div class="grid">
        <div :class="[!validator.re_password && data.re_password === '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-question"></i>
        </div>
        <div :class="[validator.re_password && data.re_password === '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-question"></i>
        </div>
        <div :class="[validator.re_password && data.re_password !== '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-check" style="color: #42b983"></i>
        </div>
        <div :class="[!validator.re_password && data.re_password !== '' ? 'icon' : 'hide']">
          <i class="fa-regular fa-circle-xmark" style="color: #ea0c68"></i>
        </div>
        <input placeholder="Powtórz hasło" type="password" @change="valid_rePassword" id="rePassword" v-model="data.re_password">
        <div class="hint_Text">{{ hint.re_password}}</div>
      </div>
    </div>
    <div :class="[validator.re_password ? 'group_element' : 'none']">
      <div class="text">
        Zaakceptuj wszystko
      </div>
      <div class="input_box">
        <input type="checkbox" class="checbox" id="checkBoxAll" v-model="data.accept_all" @click="click_accept_all">
      </div>
      <div class="accept_box">
        <div>
          <div class="text">
            Zaakceptuj regulamin oraz politykę prywatności*
          </div>

          <div class="input_box">
            <input type="checkbox" class="checbox" id="checkBoxRules" v-model="data.accept_rules">
          </div>
          <div class="text2">
            Wyrażam zgodę na przetwarzanie przez tapo24.pl danych osobowych podanych w formularzu. Podanie danych jest
            dobrowolne. Administratorem podanych przez Pana/ Panią danych osobowych jest administrator wyznaczony w
            zakładce kontakt. Pana/Pani dane będą przetwarzane w celach związanych z udzieleniem odpowiedzi,
            przedstawieniem oferty usług tapo24.pl oraz świadczeniem usług przez administratora danych. Przysługuje
            Panu/Pani prawo dostępu do treści swoich danych oraz ich poprawiania.
          </div>
          <div class="text" @click="gen_pdf('Regulamin.pdf','Regulamin serwisu Tapo24.pl')">
            Regulamin
          </div>
          <div class="text" @click="gen_pdf('POLITYKA.pdf','Polityka prywatności serwisu Tapo24.pl')">
            Polityka prywatności
          </div>
        </div>
        <div>
          <div class="text">
            Zaakceptuj powiadomienia
          </div>
          <div class="input_box">
            <input type="checkbox" class="checbox" id="checkBoxAdv" v-model="data.accept_adv">
          </div>
          <div class="text2">
            Wyrażam zgodę na otrzymywanie informacji handlowych od Tapo24.pl dotyczących jej oferty oraz ofert jej partnerów
            biznesowych poprzez wiadomości typu push w aplikacji oraz z wykorzystaniem adresu email podanego w formularzu,
            a także zgodę na przetwarzanie moich danych osobowych w tym celu przez Tapo24.pl oraz w celach promocji,
            reklamy i badania rynku.
          </div>
        </div>
      </div>
      <div class="hint_Text_register" >*Zgoda wymagana</div>
    </div>
    <div class="orange_button" v-if="data.accept_rules && validator.re_password" @click="onFormFinish">Zarejestruj się  <i class="fa-solid fa-right-to-bracket"></i></div>
    <div class="orange_button_disabled" v-if="!data.accept_rules || !validator.re_password" >Zarejestruj się  <i class="fa-solid fa-right-to-bracket"></i></div>
    <div class="hint_Text_register" v-if="!data.accept_rules || !validator.re_password">Wprowadź wszystkie wymagane dane</div>

  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'RegisterForm',
  data () {
    return {
      validator: {
        email: false,
        login: false,
        password: false,
        re_password: false
      },
      data: {
        email: '',
        login: '',
        password: '',
        re_password: '',
        accept_rules: false,
        accept_adv: false,
        accept_all: false
      },
      hint: {
        email: '',
        login: '',
        password: '',
        re_password: ''
      },
      payloadRegister: {
        login: '',
        password: '',
        email: '',
        acceptRules: '',
        acceptAdv: ''
      }

    }
  },
  watch: {
    'data.email' () {
      this.valid_email()
    },
    'data.login' () {
      this.valid_login()
    },
    'data.password' () {
      this.valid_password()
    },
    'data.re_password' () {
      this.valid_rePassword()
    },
    'data.accept_adv' () {
      this.valid_accept_all()
    },
    'data.accept_rules' () {
      this.valid_accept_all()
    }
  },
  created () {
    this.data = this.registerData
    // this.registerData.email = ''
    // this.registerData.login = ''
    // this.registerData.password = ''
    // this.registerData.re_password = ''
    // this.registerData.accept_rules = false
    // this.registerData.accept_adv = false
    // this.registerData.accept_all = false
  },
  computed: {
    ...mapState([
      'registerData'
    ])
  },
  methods: {
    gen_pdf (path, fileName) {
      this.registerData = this.data
      this.$router.push({ name: 'PdfViewer', params: { pdf_name: fileName, pdf_path: path, from: 'Register' } })
    },
    hideForm () {
      document.getElementById('form').style.visibility = 'hidden'
      document.getElementById('form').style.display = 'none'
    },
    click_accept_all () {
      if (this.data.accept_all === false) {
        this.data.accept_adv = true
        this.data.accept_rules = true
        this.data.accept_all = true
      } else {
        this.data.accept_all = false
        this.data.accept_adv = false
        this.data.accept_rules = false
      }
    },
    keypress_email () {
      document.getElementById('login_input').select()
    },
    keypress_login () {
      document.getElementById('password').select()
    },
    keypress_password () {
      document.getElementById('rePassword').select()
    },
    valid_accept_all () {
      if (this.data.accept_adv && this.data.accept_rules) {
        this.data.accept_all = true
      } else {
        this.data.accept_all = false
      }
    },
    valid_email () {
      if (this.data.email.match(
        /* eslint-disable no-useless-escape */
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        this.payloadRegister.email = this.data.email.trim()
        const headers = {
          'Content-Type': 'application/json',
          'INSTALLATION-UID': this.$store.state.UID
        }
        axios
          .post(this.$store.state.path_api + '/register/check_email', this.payloadRegister, { headers })
          .then(response => {
            this.validator.email = true
            this.hint.email = ''
          })
          .catch(error => {
            this.validator.email = false
            document.getElementById('email_input').className = ''
            document.getElementById('email_input').className = 'test'
            if (error.response.status === 409) {
              this.hint.email = 'Ten email jest już u nas zarejestrowany jeżeli uważasz to za błąd napisz do nas'
            } else {
              this.hint.email = 'Cos poszło nie tak :(' + error
            }
          })
        document.getElementById('email_input').className = ''
      } else {
        this.validator.email = false
        this.hint.email = 'Wprowadź poprawny email'
        document.getElementById('email_input').className = ''
        document.getElementById('email_input').className = 'test'
      }
    },
    valid_login () {
      if (this.data.login.match(
        /* eslint-disable no-useless-escape */
        /^[a-zA-Z0-9_.-]{5,}$/
      )) {
        document.getElementById('login_input').className = ''
        this.validator.login = true
        this.hint.login = ''
        this.payloadRegister.login = this.data.login.trim()
        const headers = {
          'Content-Type': 'application/json',
          'INSTALLATION-UID': this.$store.state.UID
        }
        axios
          .post(this.$store.state.path_api + '/register/check_login', this.payloadRegister, { headers })
          .then(response => {
            this.validator.login = true
            this.hint.login = ''
          })
          .catch(error => {
            this.validator.login = false
            document.getElementById('login_input').className = ''
            document.getElementById('login_input').className = 'test'
            if (error.response.status === 409) {
              this.hint.login = 'Ten login jest już u nas zarejestrowany jeżeli uważasz to za błąd napisz do nas'
            } else {
              this.hint.login = 'Cos poszło nie tak :(' + error
            }
          })
      } else {
        this.validator.login = false
        document.getElementById('login_input').className = ''
        document.getElementById('login_input').className = 'test'
        this.hint.login = 'Wprowadź login do długości conajmniej 5 znaków składający się tylko z znaków i cyfr np test1'
      }
    },
    valid_password () {
      if (this.data.password.length >= 8 && /\d/.test(this.data.password) && /[a-z]/.test(this.data.password) && /[A-Z]/.test(this.data.password) && /[!@#$%^&*)(+=._-]/.test(this.data.password)) {
        document.getElementById('password').className = ''
        this.hint.password = ''
        this.validator.password = true
      } else {
        document.getElementById('password').className = ''
        document.getElementById('password').className = 'test'
        this.hint.password = 'Hasło musi mieć minimalną długość 8 znaków oraz zawierać 1 dużą i małą literę, liczbę, znak specjalny'
      }
    },
    valid_rePassword () {
      if (this.validator.password) {
        if (this.data.password === this.data.re_password) {
          document.getElementById('rePassword').className = ''
          this.validator.re_password = true
          this.hint.re_password = ''
        } else {
          this.validator.re_password = false
          document.getElementById('rePassword').className = ''
          document.getElementById('rePassword').className = 'test'
          this.hint.re_password = 'Wprowadź ponownie te same hasło'
        }
      } else {
        this.validator.re_password = false
        document.getElementById('rePassword').className = ''
        document.getElementById('rePassword').className = 'test'
        this.hint.re_password = 'Wprowadź najpierw poprawne hasło!'
      }
    },
    onFormFinish () {
      if (this.validator.password &&
        this.validator.email &&
        this.validator.re_password &&
        this.validator.login &&
        this.data.accept_rules) {
        this.payloadRegister.password = this.data.password
        this.payloadRegister.acceptAdv = this.data.accept_adv
        this.payloadRegister.email = this.data.email.trim()
        this.payloadRegister.login = this.data.login.trim()
        this.payloadRegister.acceptRules = this.data.accept_rules
        this.$emit('formFinish', this.payloadRegister)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/main_layout";
@import "src/views/others";

.main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.password_and_forgot {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.orange_button {
  margin-top: 10px;
  width: auto;
  justify-content: center;
}
.orange_button_disabled {
  background-color: #293241;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-family: 'Readex Pro', sans-serif;
  font-weight: bold;
  transition: 0.5s;
  margin-top: 10px;
  width: auto;
  justify-content: center;
}
.test {
  background: #ff0000;
  border: 5px solid #bd0000;
  border-radius: 5px;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
input{
  width: auto;
  border-width: 0;
  border-radius: 5px;
  padding: 4px 10px;
  margin-left: 10px;
}
.group_element{
  font-family: $text-family;
  font-weight: bold;
  transition: 1s;
}
.input_box{
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}
.text {
  margin-bottom: 10px;
  text-align: center;
}
.text2 {
  margin-bottom: 10px;
  text-align: center;
  font-weight: normal;
  font-size: 9px;
}
.small_main_container{
  margin: 20px 10px 10px 10px;
  transition: height 1s;
  transition-timing-function: ease;
}
.checbox{
  font-size: 35px;
}
.hint_Text {
  grid-row-start: 2;
  grid-column-start: 2;
  margin-left: 10px;
  font-weight: lighter;
  font-size: 12px;
}
.hint_Text_register{
  font-weight: lighter;
  font-size: 12px;
  text-align: center;
}
.hide {
  visibility: hidden;
  display: none;
}
.grid {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: auto;
  grid-gap: 6px;
  max-width: 1080px;
  margin: 0 auto !important;
  float: none !important;
  align-items: center;
}
.none {
  display: none;
}
.accept_box{
  display: flex;
  justify-content: space-around;
  font-size: 12px;
}

@media only screen and (min-width: 700px) {
  .small_main_container {
    width: 400px;
  }
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
