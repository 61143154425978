<template>
  <div class="main">
    <div>
      <RegisterForm
      @formFinish="registerUser"
      ref="registerForm"
      />
      <waiting
        ref="waitingForm"
      />
      <Success
        ref="successForm"
      />
      <div class="footer" @click="click_to_login">
        {{logAccountText}} <i class="fa-regular fa-hand-pointer"></i>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterForm from '@/components/Register/RegisterForm'
import waiting from '@/components/Register/Waiting'
import axios from 'axios'
import Success from '@/components/Register/Success'
export default {
  name: 'Register',
  components: {
    Success,
    RegisterForm,
    waiting
  },
  data () {
    return {
      logAccountText: 'Masz już konto? Zaloguj się'
    }
  },
  methods: {
    click_to_login () {
      this.$router.push({ name: 'Login' })
    },
    registerUser (payload) {
      this.$refs.waitingForm.showWaiting()
      this.$refs.registerForm.hideForm()
      setTimeout(() => {
        const headers = {
          'Content-Type': 'application/json',
          'INSTALLATION-UID': this.$store.state.UID
        }
        axios
          .post(this.$store.state.path_api + '/register/registerUser', payload, { headers })
          .then(successResponse => {
            this.$refs.waitingForm.hideWaiting()
            this.$refs.successForm.showSuccess()
            this.logAccountText = 'Zaloguj się'
          })
          .catch(error => {
            this.$refs.waitingForm.hideWaiting()
            this.$store.state.error = error
            this.$store.state.error_response = error.response
            this.$store.state.proces_Name = 'Register User'
            this.$store.state.error_message = error.response.data
            this.$router.push({ name: 'ErrorPage' })
          })
      }, 1000)

      // this.$refs.registerForm.data.login = 'sadasdasd'
    }
  },
  mounted () {
    // document.getElementById('waiting').style.visibility = 'hidden'
    // document.getElementById('waiting').style.display = 'none'
  }
}
</script>

<style scoped lang="scss">
@import "@/views/main_layout.scss";
@import "@/views/others.scss";

@media only screen and (max-width: 700px) {
  .small_main_container{
    margin: 150px 10px 10px 10px;
    transition: height 1s;
    transition-timing-function: ease;
  }
}
.footer{
  margin: 10px 10px;
  width: auto;
  background-color: #3D5A80;
  border-radius: 8px;
  padding: 20px 10px;
  height: min-content;
  font-family: $text-family;
  font-weight: bold;
  text-align: center;
  transition: 0.5s;
}
.footer:hover {
  cursor: pointer;
  background-color: #9cbad3;
}
</style>
