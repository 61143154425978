export default {
  alert_show: false,
  favorites_offenses_array: [],
  scroll_pos_tariff: 0,
  ini_back: true,
  open_pdf: false,
  offline_stack_moduleName: [],
  offline_stack_moduleName2: [],
  load_api_pdf: false,
  path_api: 'https://api3.tapo24.pl/api',
  // path_api: 'http://192.168.0.59:8081/api',
  // path_api: 'http://localhost:8081/api',
  // path_api: 'https://develop.api3.tapo24.pl/api',
  selected_tariff_data: {
    options_category: 'all',
    search_text: ''
  },
  block_ini_logo: false,
  UID: '',
  exp_token_date: 0,
  version: '',
  speed_limit_save: '',
  permissions: {},
  new_law: false,
  new_law_serach: true,
  api_key_to_stats_beta: 'API SET',
  api_key_to_stats: 'API ldwSsspQroSJEO1',
  error: '',
  error_message: '',
  error_response: '',
  proces_Name: '',
  develop: false,
  lunched: false,
  bearer: '',
  isLogin: false,
  isAdmin: false,
  userData: {
    login: 'SampleLogin',
    email: 'SampleEmail@sample.pl',
    dateCreatedAccount: '1682267986',
    status: 'Użytkownik'
  },
  registerData: {
    email: '',
    login: '',
    password: '',
    re_password: '',
    accept_rules: false,
    accept_adv: false,
    accept_all: false
  },
  notificationAdminData: [],
  notificationListData: [],
  notificationIncoming: false,
  notificationCount: 0,
  notificationBlock: false,
  notificationProcessing: false
}
