<template>
  <div class="small_main_container" id="success">
    <div>
      <div>
        Poprawnie zarejestrowano
      </div>
      <i class="far fa-check-circle" style="font-size: 40px"></i>
      <div class="smallText">
        <div>
          Na podany adres e-mail został wysłany link z aktywacją konta w serwisie. Dziękujemy za wybranie naszej
          aplikacji. Link ważny jest 5 minut od wygenerowania. Zachęcamy do polubienia nas na Facebooku oraz Instagramie.
        </div>
        <div class="social">
          <div class="social facebook" @click="globalGoTo('https://www.facebook.com/TaPo24/','Link do strony ', 'web', 'SOCIAL')">
            <div class="icon social facebook"><span><i class="fab fa-facebook-f"></i></span></div>
          </div>
          <div class="social instagram" @click="globalGoTo('https://www.instagram.com/tapo24.pl/','Link do strony IG', 'web', 'SOCIAL')">
            <div class="icon social instagram"><span><i class="fab fa-instagram"></i></span></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success',
  methods: {
    showSuccess () {
      document.getElementById('success').style.visibility = 'unset'
      document.getElementById('success').style.display = 'flex'
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/views/main_layout";
@import "src/views/others";

#success {
  font-family: $text-family;
  // display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  display: none;
  visibility: hidden;
}
.smallText {
  font-size: 12px;
  overflow-wrap: normal;
}
.social{
  display: inline-flex;
  list-style: none;
}
.social .icon{
  background: #293241;
  color: #ee6c4d;
  border-radius: 50%;
  padding: 5px;
  width: 15px;
  height: 15px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@media only screen and (min-width: 700px) {
  .small_main_container {
    width: 400px;
  }
}

</style>
